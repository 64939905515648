import activityTypes, {
    getTabActivityType,
} from "../../constants/activityTypes";
import timerStatus from "../../constants/timerStatus";
import requestStatus from "../../constants/requestStatus";
import caseActivityService from "../../services/caseActivityService";
import {
    startTimingActivity,
    stopCaseTimer,
    addUnallocatedActivities,
    filterSavedActivities,
    setRequestStatus,
    queueBlur,
} from "../actions/caseTimerActions";
import {
    setCallStart,
    setCallEnd,
    clearUnallocatedCalls,
} from "../actions/flexActions";
import { setUserTimerRunning } from "../actions/userActions";
import {
    OPEN_FILE_VIEWER,
    CLOSE_FILE_VIEWER,
    SET_CALL_NOTE_ISSUE_FOCUS,
    SET_CALL_NOTE_ADVICE_FOCUS,
    SET_CALL_NOTE_ACTION_FOCUS,
    SET_INTERNAL_NOTE_FOCUS,
    SET_CASE_VIEWSTATE,
    SET_CURRENT_CASE,
    SET_USER_TIMER_RUNNING,
    SET_CASE_CLOSED,
    SET_IN_CALL,
    SET_FLEX_WINDOW_STATUS,
    SET_CASE_WINDOW_STATUS,
    ADD_CASE_EVENT,
    SET_REQUEST_STATUS,
    SET_CALL_NOTE_INTERACTED,
    QUEUE_BLUR,
    CLEAR_UNALLOCATED_CALLS,
    SET_CONFIRM_CLOSE_OPEN,
    SET_CASE_RESEARCH_OPEN,
    SET_INTERNAL_NOTE_DESCRIPTION_FOCUS,
    SET_CALL_NOTE_DESCRIPTION_FOCUS,
    SET_MISC_ACTIVITY_TAB,
} from "../constants";
import { miscActivityGroups } from "../../constants/miscActivityGroupConstants";
import userService from "../../services/userService";
import caseTab from "../../constants/caseTab";

const validTypes = [
    // document modals
    OPEN_FILE_VIEWER,
    CLOSE_FILE_VIEWER,
    // call note
    SET_CALL_NOTE_ISSUE_FOCUS,
    SET_CALL_NOTE_ADVICE_FOCUS,
    SET_CALL_NOTE_ACTION_FOCUS,
    SET_CALL_NOTE_DESCRIPTION_FOCUS,
    // internal note
    SET_INTERNAL_NOTE_FOCUS,
    SET_INTERNAL_NOTE_DESCRIPTION_FOCUS,
    // switching case tabs
    SET_CASE_VIEWSTATE,
    // switching cases
    SET_CURRENT_CASE,
    // changing routes
    "@@router/LOCATION_CHANGE",
    // user timer
    SET_USER_TIMER_RUNNING,
    // case closed
    SET_CASE_CLOSED,
    // calls
    SET_IN_CALL,
    // app focus
    SET_FLEX_WINDOW_STATUS,
    SET_CASE_WINDOW_STATUS,
    // case events
    ADD_CASE_EVENT,
    // save activities request
    SET_REQUEST_STATUS,
    // call note interacted with
    SET_CALL_NOTE_INTERACTED,
    // blur queued
    QUEUE_BLUR,
    // unallocated calls cleared
    CLEAR_UNALLOCATED_CALLS,
    // confirm close case dialog opened
    SET_CONFIRM_CLOSE_OPEN,
    // case research mode
    SET_CASE_RESEARCH_OPEN,
    // misc activity tab change
    SET_MISC_ACTIVITY_TAB,
];

const caseActivityTracker =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        // callNext is called when starting a null activity and it is required state is updated first (i.e. the action that is intercepted needs to pass through reducer first)
        // ensure next is only called once throughout the middleware
        let nextCalled = false;
        const callNext = () => {
            nextCalled = true;
            next(action);
        };

        const currentState = getState();
        const { caseReducer, flexReducer, caseTimerReducer, userReducer } =
            currentState;
        const {
            currentCaseId,
            cases,
            confirmCloseOpen,
            caseResearch,
        } = caseReducer;
        const { inCall, unallocatedCalls, currentCallId } = flexReducer;
        const { isUserTimerRunning, documentViewer } = userReducer;
        const {
            caseWindowStatus,
            flexWindowStatus,
            status: caseTimerStatus,
            activities,
            saveRequestStatus,
            currentActivity,
        } = caseTimerReducer;
        const currentCase = cases[currentCaseId];
        const caseResearchOpen = caseResearch.open;

        const shouldStartActivity =
            currentCase &&
            (!isUserTimerRunning ||
                (action.type === SET_USER_TIMER_RUNNING &&
                    !action.isUserTimerRunning)) &&
            (!unallocatedCalls.length ||
                action.type === CLEAR_UNALLOCATED_CALLS) &&
            (!currentCase.caseSummary.dateClosed ||
                (currentCase.caseSummary.dateClosed &&
                    action.type === SET_CASE_CLOSED &&
                    !action.dateClosed));

        const sendActivitiesToApi = async (isCallEnd = false) => {
            if ((!activities.length && !currentActivity) || saveRequestStatus === requestStatus.PENDING)
                return;

            const callId = isCallEnd ? currentCallId : null;

            try {
                dispatch(setRequestStatus(requestStatus.PENDING));
                const savedActivityIds = await caseActivityService.sendActivities(callId);
                dispatch(filterSavedActivities(savedActivityIds));
                dispatch(setRequestStatus(requestStatus.RESOLVED));
            } catch (error) {
                dispatch(setRequestStatus(requestStatus.REJECTED));
                throw error;
            }
        };

        const stopActivity = () => {
            if (caseTimerStatus === timerStatus.RUNNING)
                dispatch(stopCaseTimer());
        };

        const startActivity = (activityType) => {
            if (!shouldStartActivity) {
                stopActivity();
                return;
            }

            const shouldAppendCall = () => {
                if (activityType)
                    return (!activityType.toLowerCase().endsWith("call") && inCall);

                if (action.type === SET_IN_CALL)
                    return action.payload.inCall;

                return inCall;
            };

            dispatch(startTimingActivity(activityType, shouldAppendCall()));
        };

        const isRetrospectiveMiscOpen = () =>
            !!(
                currentCase &&
                currentCase.miscActivity.open &&
                currentCase.miscActivity.tab === miscActivityGroups.RETROSPECTIVE
            );

        const handleUnallocatedCalls = () => {
            if (
                unallocatedCalls.length &&
                currentCaseId &&
                !currentCase.caseSummary.dateClosed
            ) {
                dispatch(
                    addUnallocatedActivities({
                        caseId: currentCaseId,
                        unallocatedActivities: unallocatedCalls,
                    })
                );
                dispatch(clearUnallocatedCalls());
            }
        };

        const handleFileViewerAction = () => {
            if (action.type === OPEN_FILE_VIEWER) {
                handleUnallocatedCalls();
                startActivity(activityTypes.FILE);
                return;
            }

            if (isRetrospectiveMiscOpen() && !inCall) {
                stopActivity();
                return;
            }

            callNext();
            startActivity(null);
        };

        const handleWindowFocusAction = () => {
            const handleBlur = async () => {
                try {
                    // isCallEnd set to true as its though blur triggered when user clicks hang up in flex from main app
                    await sendActivitiesToApi(true);
                } catch (error) {
                    console.error(`Blurring app: ${error}`);
                }
                dispatch(queueBlur());
            };

            switch (action.type) {
                case SET_CASE_WINDOW_STATUS:
                    if (action.status === "focus") {
                        if (
                            !inCall &&
                            ((isRetrospectiveMiscOpen() &&
                                currentCase.viewState.currentTab === caseTab.DETAILS &&
                                !documentViewer.open) ||
                                confirmCloseOpen ||
                                caseResearchOpen)
                        ) {
                            stopActivity();
                            return;
                        }
                        userService.updateLastActive();
                        startActivity(activityTypes.RESUME_CASE_ACTIVITY);
                    } else if (action.status === "blur" && flexWindowStatus === "blur") {
                        handleBlur();
                    } else if (
                        action.status === "blur" &&
                        flexWindowStatus === "focus"
                    ) {
                        userService.updateLastActive();
                        startActivity(activityTypes.FLEX);
                    }
                    break;
                case SET_FLEX_WINDOW_STATUS:
                    if (action.status === "focus") {
                        if (caseWindowStatus === "blur") {
                            userService.updateLastActive();
                            startActivity(activityTypes.FLEX);
                            return;
                        }
                    } else if (
                        action.status === "blur" &&
                        caseWindowStatus === "blur"
                    )
                        handleBlur();
                    else if (
                        action.status === "blur" &&
                        caseWindowStatus === "focus"
                    ) {
                        if (
                            !inCall &&
                            ((isRetrospectiveMiscOpen() &&
                                currentCase.viewState.currentTab === caseTab.DETAILS &&
                                !documentViewer.open) ||
                                confirmCloseOpen ||
                                caseResearchOpen)
                        ) {
                            stopActivity();
                            return;
                        }
                        userService.updateLastActive();
                        startActivity(activityTypes.RESUME_CASE_ACTIVITY);
                    }
                    break;
                default:
                    break;
            }
        };

        const handleCallNoteActivity = () => {
            if (action.isFocus) {
                handleUnallocatedCalls();
                startActivity(activityTypes.CALL_NOTE);
                return;
            }

            callNext();
            startActivity(null);
        };

        const handleInternalNoteActivity = () => {
            if (action.hasFocus) {
                handleUnallocatedCalls();
                startActivity(activityTypes.INTERNAL_NOTE);
                return;
            }

            callNext();
            startActivity(null);
        };

        const handleCaseTabChange = () => {
            if (
                action.viewState.currentTab !== currentCase.viewState.currentTab
            ) {
                handleUnallocatedCalls();
                if (
                    isRetrospectiveMiscOpen() &&
                    !inCall &&
                    action.viewState.currentTab === caseTab.DETAILS
                ) {
                    stopActivity();
                    return;
                }
                startActivity(getTabActivityType(action.viewState.currentTab));
            }
        };

        const handleCaseIdChange = async () => {
            try {
                await sendActivitiesToApi();
            } catch (error) {
                console.error(`Changing Case ID: ${error}`);
            }

            if (
                unallocatedCalls.length &&
                action.caseId &&
                !cases[action.caseId].caseSummary.dateClosed
            ) {
                dispatch(
                    addUnallocatedActivities({
                        caseId: action.caseId,
                        unallocatedActivities: unallocatedCalls,
                    })
                );
                dispatch(clearUnallocatedCalls());
                return;
            }

            const c = cases[action.caseId];

            if (
                action.caseId &&
                c?.miscActivity.open &&
                c.miscActivity.tab === miscActivityGroups.RETROSPECTIVE &&
                !inCall &&
                c.viewState.currentTab === caseTab.DETAILS
            ) {
                stopActivity();
                return;
            }

            callNext();
            startActivity(null);
        };

        const handlePageChange = () => {
            if (action.payload.action === "PUSH") {
                if (action.payload.location.pathname !== "/case") {
                    if (inCall) {
                        startActivity(activityTypes.BLUR_CALL);
                        return;
                    }
                    stopActivity();
                    return;
                }

                if (isUserTimerRunning) dispatch(setUserTimerRunning(false));
            }
        };

        const handleUserTimerChange = () => {
            if (
                action.isRunning ||
                (isRetrospectiveMiscOpen() &&
                    !inCall &&
                    currentCase.viewState.currentTab === caseTab.DETAILS)
            )
                stopActivity();
            // don't callNext here as already handled in shouldStartActivity
            else startActivity(null);
        };

        const handleCaseClosed = () => {
            if (action.dateClosed) {
                stopActivity();
                return;
            }

            handleUnallocatedCalls();
            // don't callNext here as already handled in shouldStartActivity
            startActivity(null);
        };

        const handleInCallChange = async () => {
            if (action.payload.inCall) {
                userService.updateInCall({ inCall: true });
                dispatch(setCallStart({ callId: action.payload.callId }));
                stopActivity();
                return;
            }
            userService.updateInCall({ inCall: false });
            dispatch(setCallEnd());
            if (
                (caseWindowStatus === "blur" && flexWindowStatus === "blur") ||
                (isRetrospectiveMiscOpen() &&
                    currentCase.viewState.currentTab === caseTab.DETAILS &&
                    !documentViewer.open) ||
                confirmCloseOpen ||
                caseResearchOpen
            ) {
                stopActivity();
                return;
            }

            try {
                await sendActivitiesToApi(true);
            } catch (error) {
                console.error(`Call ended: ${error}`);
            }

            callNext();
            startActivity(null);
        };

        const handleRequestStatusChange = () => {
            if (action.payload === requestStatus.RESOLVED) {
                if (
                    flexWindowStatus === "blur" &&
                    caseWindowStatus === "blur"
                ) {
                    if (inCall) startActivity(activityTypes.BLUR_CALL);
                    return;
                }
                if (
                    !inCall &&
                    ((isRetrospectiveMiscOpen() &&
                        currentCase.viewState.currentTab === caseTab.DETAILS &&
                        !documentViewer.open) ||
                        confirmCloseOpen ||
                        caseResearchOpen)
                ) {
                    stopActivity();
                    return;
                }
                startActivity(null);
            }
        };

        const handleCallNoteInteracted = () => {
            handleUnallocatedCalls();
            startActivity(null);
        };

        const handleQueueBlur = () => {
            if (caseWindowStatus === "focus" || flexWindowStatus === "focus")
                return;

            if (inCall) {
                startActivity(activityTypes.BLUR_CALL);
                return;
            }

            stopActivity();
        };

        const handleUnallocatedCallsCleared = () => {
            if (
                !inCall &&
                ((isRetrospectiveMiscOpen() &&
                    currentCase.viewState.currentTab === caseTab.DETAILS &&
                    !documentViewer.open) ||
                    confirmCloseOpen ||
                    caseResearchOpen)
            ) {
                stopActivity();
                return;
            }
            // don't callNext here as already handled in shouldStartActivity
            startActivity(null);
        };

        const handleConfirmCloseCaseChange = () => {
            if (inCall) return;

            if (action.isOpen) {
                stopActivity();
                return;
            }

            if (
                isRetrospectiveMiscOpen() &&
                currentCase.viewState.currentTab === caseTab.DETAILS
            ) {
                stopActivity();
                return;
            }

            startActivity(null);
        };

        const handleCaseResearchChange = () => {
            if (inCall) return;

            if (action.isOpen) {
                stopActivity();
                return;
            }

            if (
                isRetrospectiveMiscOpen() &&
                currentCase.viewState.currentTab === caseTab.DETAILS
            ) {
                stopActivity();
                return;
            }

            startActivity(null);
        };

        const handleMiscActivityTabChange = () => {
            if (inCall) return;

            if (action.payload.tab === miscActivityGroups.RETROSPECTIVE) {
                stopActivity();
                return;
            }

            startActivity(null);
        };

        if (validTypes.includes(action.type)) {
            switch (action.type) {
                case OPEN_FILE_VIEWER:
                case CLOSE_FILE_VIEWER:
                    handleFileViewerAction();
                    break;
                case SET_FLEX_WINDOW_STATUS:
                case SET_CASE_WINDOW_STATUS:
                    handleWindowFocusAction();
                    break;
                case SET_CALL_NOTE_ISSUE_FOCUS:
                case SET_CALL_NOTE_ADVICE_FOCUS:
                case SET_CALL_NOTE_ACTION_FOCUS:
                case SET_CALL_NOTE_DESCRIPTION_FOCUS:
                    handleCallNoteActivity();
                    break;
                case SET_INTERNAL_NOTE_FOCUS:
                case SET_INTERNAL_NOTE_DESCRIPTION_FOCUS:
                    handleInternalNoteActivity();
                    break;
                case SET_CASE_VIEWSTATE:
                    handleCaseTabChange();
                    break;
                case SET_CURRENT_CASE:
                    handleCaseIdChange();
                    break;
                case "@@router/LOCATION_CHANGE":
                    handlePageChange();
                    break;
                case SET_USER_TIMER_RUNNING:
                    handleUserTimerChange();
                    break;
                case SET_CASE_CLOSED:
                    handleCaseClosed();
                    break;
                case SET_IN_CALL:
                    handleInCallChange();
                    break;
                case SET_REQUEST_STATUS:
                    handleRequestStatusChange();
                    break;
                case SET_CALL_NOTE_INTERACTED:
                    handleCallNoteInteracted();
                    break;
                case QUEUE_BLUR:
                    handleQueueBlur();
                    break;
                case CLEAR_UNALLOCATED_CALLS:
                    handleUnallocatedCallsCleared();
                    break;
                case SET_CONFIRM_CLOSE_OPEN:
                    handleConfirmCloseCaseChange();
                    break;
                case SET_CASE_RESEARCH_OPEN:
                    handleCaseResearchChange();
                    break;
                case SET_MISC_ACTIVITY_TAB:
                    handleMiscActivityTabChange();
                    break;
                default:
                    break;
            }
        }

        if (!nextCalled) return next(action);
    };

export default caseActivityTracker;
