import * as types from "../constants";
import emailService from "../../services/emailService";
import caseActivityService from "../../services/caseActivityService";
import emailDraftService from "../../services/emailDraftService";
import unallocatedCaseTimeService from "../../services/unallocatedCaseTimeService";
import {
    addCaseEventAction,
    updateCaseChargeableTime,
    clearCaseDraftEmail,
    setCaseViewStateAction,
    incrementEventCount,
    setAdjustCaseMinutes,
    setCallNoteText,
    setCallNoteCall,
    setCallNoteChat,
    setCallNoteCaseActivityId,
    setCallNoteMode,
    setCaseActivityIds,
    updateCallNote,
    updateInternalNote,
    setInternalNoteText,
    setInternalNoteCaseActivityId,
    updateCaseDraftEmail,
    setEditingDraft,
    setBackdropOpen,
    setCallNoteDescription,
    setInternalNoteDescription,
    setReplyAttachmentAzureFileIdsAction,
    setActivityManage,
} from "./caseActions";
import { setSnackAction } from "./snackActions";
import { setRequestStatus, filterSavedActivities } from "./caseTimerActions";
import caseTabs from "../../constants/caseTabs";
import caseTab from "../../constants/caseTab";
import { emptyCall, emptyChat, emptyCallNote } from "../../constants/emptyActivities";
import caseActivityTypes from "../../constants/caseActivityTypes";
import requestStatus from "../../constants/requestStatus";
import callNoteModes from "../../constants/callNoteModes";
import buildSaveDraftEmailRequest from "../../utils/buildDraftEmailRequest";
import caseTimeEventGroupService from "../../services/caseTimeEventGroupService";
import reactQueryClient from "../../reactQueryClient";
import queryKeys from "../../constants/queryKeys";

// private methods
const sendActivitiesToApi = async ({ dispatch, saveRequestStatus, activities, currentActivity }) => {
    if ((!activities.length && !currentActivity) || saveRequestStatus === requestStatus.PENDING) return;

    try {
        dispatch(setRequestStatus(requestStatus.PENDING));
        const savedActivityIds = await caseActivityService.sendActivities();
        dispatch(filterSavedActivities(savedActivityIds));
        dispatch(setRequestStatus(requestStatus.RESOLVED));
    } catch (error) {
        dispatch(setRequestStatus(requestStatus.REJECTED));
        throw error;
    }
};

const resetCallCard = ({ dispatch, caseId }) => {
    dispatch(setCallNoteText(emptyCallNote, caseId));
    dispatch(setCallNoteCall(emptyCall, caseId));
    dispatch(setCallNoteChat(emptyChat, caseId));
    dispatch(setActivityManage(caseId, null));    
    dispatch(setCallNoteCaseActivityId(null, caseId));
    dispatch(setCallNoteMode(callNoteModes.CALL, caseId));
    dispatch(setCallNoteDescription(""));
};

// exports
export function sendEmail({
    contractId,
    event,
    files,
    isAdvisor,
    from,
    draftAttachmentIds,
    caseTimeEventGroupIds,
    liveCaseDocumentIds,
    azureFileIds,
    sizeExcludingLiveDocs,
    draftId,
    draftEmailsSummary
}) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { userId } = userState.userProfile;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const contractId = c?.caseSummary?.contractId;

        event.isAssisted = c.aiState.used === true;

        // method to send email
        const sendEmailAsync = async () => {
            try {
                // set saving = true
                dispatch({ type: types.SEND_EMAIL_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // send email
                const response = await emailService.sendEmail({
                    caseId,
                    contractId,
                    event,
                    files,
                    draftAttachmentIds,
                    caseTimeEventGroupIds,
                    liveCaseDocumentIds,
                    azureFileIds,
                    sizeExcludingLiveDocs,
                    isSaasCase: c.isSaasCase,
                });

                if (event.isAssisted)
                    dispatch({
                        type: types.SET_CASE_AI_STATE,
                        caseId,
                        payload: {
                            used: false
                        }
                    });

                if (caseTimeEventGroupIds?.length)
                    reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups, userId]);
                
                // dispatch addCaseEventAction
                dispatch(
                    addCaseEventAction(caseId, {
                        ...event,
                        attachments: response.data.attachmentDTOs,
                        duration: response.data.duration,
                        content: response.data.content,
                        caseActivityId: response.data.activityId,
                        isChargeable: response.data.isChargeable,
                    })
                );

                // update case chargeable time
                if (response.data.isChargeable)
                    dispatch(
                        updateCaseChargeableTime({
                            caseId,
                            delta: response.data.duration,
                        })
                    );

                // post message to flex
                if (isAdvisor)
                    window.frames[0].postMessage({ event: "latestEmailReply", caseId }, "*");

                // remove draft email using draftId
                if (draftId)
                    await emailDraftService.removeDraft(draftId)

                dispatch(
                    setCaseViewStateAction(caseId, {
                        ...c.viewState,
                        mode: caseTabs.normal,
                        currentTab: caseTab.DETAILS,
                    })
                );

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                dispatch({ type: types.SEND_EMAIL_SUCCESS });

                dispatch(clearCaseDraftEmail({ caseId }));
                if (draftEmailsSummary)
                    await draftEmailsSummary.refetch();
            } catch (e) {
                dispatch({ type: types.SEND_EMAIL_FAIL });
                throw e;
            }
        };

        try {
            await sendEmailAsync();            
        } catch (e) {
            const data = e?.response?.data;
            const errorText = data?.reasons?.some((r) => r.message === "ErrorSendAsDenied")
                ? `User not authorized to send emails from email address: ${from}. Ensure that you have access rights to send from that mailbox (alternatively try clicking on the 'from' chip to change sender).`
                : data?.title ?? "There was an error sending the email";
            dispatch(setSnackAction(errorText, "error"));
        }
    };
}

export function addCallAndNote({
    activeCall,
    contactId,
    contactName,
    externalContactId,
    accountId,
    note,
    caseIsChargeable,
    isAdvisor,
    description,
    transferAllocatedCallTime,
    caseTimeEventGroupIds,
}) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { userProfile, users } = userState;
        const { userId } = userProfile;
        const caseActivityIds = c.caseActivityIds || [];
        const contractId = c?.caseSummary?.contractId;

        const addCallAndNoteAsync = async () => {
            try {
                // set saving = true
                dispatch({ type: types.ADD_CALL_AND_NOTE_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // add call and note
                const response = await caseActivityService.saveCallAndNote({
                    caseId,
                    activeCall,
                    contactId,
                    externalContactId,
                    accountId,
                    contractId,
                    userId,
                    note,
                    caseIsChargeable,
                    description,
                    transferAllocatedCallTime,
                    caseTimeEventGroupIds,
                    isSaasCase: c.isSaasCase,
                });

                reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups, userId]);

                // post message to flex
                if (isAdvisor)
                    window.frames[0].postMessage(
                        {
                            event: "callNoteAdded",
                            taskSid: activeCall.taskSid,
                            caseId,
                        },
                        "*"
                    );

                // set snack
                dispatch(setSnackAction("Successfully saved Call and Note!", "success"));

                // add comined case event
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.callActivityId,
                        adviserId: userId,
                        itemType: caseActivityTypes.CALL_WITH_NOTE,
                        eventTime: response.data.start,
                        issue: note.issue,
                        advice: note.advice,
                        action: note.action,
                        direction: activeCall.direction,
                        person: activeCall.direction === 0 ? contactName : Object.values(users).filter((u) => u.userId === userId)[0].name,                        
                        duration: response.data.duration,
                        recordingUrl: response.data.recordingUrl,
                        isChargeable: response.data.isChargeable,
                        activityDescription: description,
                    })
                );                      

                // adjust case minutes if blurDuration
                if (response.data.blurDuration > 0)
                    dispatch(setAdjustCaseMinutes(caseId, response.data.blurDuration / 60));                

                // update chargeable time
                if (response.data.isChargeable) {
                    const chargeableSeconds = response.data.callDuration + response.data.noteDuration;
                    dispatch(
                        updateCaseChargeableTime({
                            caseId,
                            delta: chargeableSeconds,
                        })
                    );
                }

                // increment event count
                dispatch(incrementEventCount(caseId));

                // set caseActivityIds (call and note)
                dispatch(setCaseActivityIds([...caseActivityIds, response.data.callActivityId, response.data.noteActivityId], caseId));

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                // reset call card
                resetCallCard({ dispatch, caseId });

                dispatch({ type: types.ADD_CALL_AND_NOTE_SUCCESS });
            } catch (e) {
                dispatch({ type: types.ADD_CALL_AND_NOTE_FAIL });
                throw new Error("There was an error saving the call and note");
            }
        };

        try {
            await addCallAndNoteAsync();
        } catch (e) {
            dispatch(setSnackAction(e.message, "error"));
        }
    };
}

export function addCall({
    activeCall,
    contactId,
    externalContactId,
    accountId,
    caseIsChargeable,
    isAdvisor,
    transferAllocatedCallTime,
    contactName
}) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { userProfile, users } = userState;
        const { userId } = userProfile;
        const caseActivityIds = c.caseActivityIds || [];
        const contractId = c?.caseSummary?.contractId;

        const addCallAsync = async () => {
            try {
                dispatch({ type: types.ADD_CALL_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // add call
                const response = await caseActivityService.saveCall({
                    caseId,
                    callId: activeCall.callId,
                    contactId,
                    externalContactId,
                    userId,
                    accountId,
                    contractId,
                    caseIsChargeable,
                    transferAllocatedCallTime,
                    isSaasCase: c.isSaasCase,
                });

                // set snack
                dispatch(setSnackAction("Successfully saved Call!", "success"));

                // add case event
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.activityId,
                        adviserId: userId,
                        itemType: caseActivityTypes.CALL,
                        eventTime: response.data.start,
                        direction: activeCall.direction,
                        person: activeCall.direction === 0 ? contactName : Object.values(users).filter((u) => u.userId === userId)[0].name,
                        duration: response.data.duration,
                        recordingUrl: response.data.recordingUrl,
                        isChargeable: response.data.isChargeable,
                    })
                );

                // increment event count
                dispatch(incrementEventCount(caseId));

                // set case activity ids
                dispatch(setCaseActivityIds([...caseActivityIds, response.data.activityId], caseId));

                // adjust case minutes
                if (response.data.blurDuration > 0) dispatch(setAdjustCaseMinutes(caseId, response.data.blurDuration / 60));

                // update case chargeable time
                if (response.data.isChargeable) {
                    dispatch(
                        updateCaseChargeableTime({
                            caseId,
                            delta: response.data.duration,
                        })
                    );
                }

                //  post message to flex
                if (isAdvisor)
                    window.frames[0].postMessage(
                        {
                            event: "callNoteAdded",
                            taskSid: activeCall.taskSid,
                            caseId,
                        },
                        "*"
                    );

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                // reset call card
                resetCallCard({ dispatch, caseId });

                dispatch({ type: types.ADD_CALL_SUCCESS });
            } catch (e) {
                dispatch({ type: types.ADD_CALL_FAIL });
                throw new Error("There was an error saving the call");
            }
        };

        try {
            await addCallAsync();
        } catch (e) {
            dispatch(setSnackAction(e.message, "error"));
        }
    };
}

export function updateNote({ caseActivityId, note, description }) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const { userProfile } = userState;
        const { userId } = userProfile;
        const c = caseState.cases[caseId];
        const contractId = c?.caseSummary?.contractId;

        try {
            dispatch({ type: types.UPDATE_NOTE_REQUEST });

            // save activities
            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            // update note
            const { data: duration } = await caseActivityService.updateNote({
                caseActivityId,
                userId,
                note,
                caseId,
                description,
            });

            // set snack
            dispatch(setSnackAction("Succesfully saved Call Note!", "success"));

            // update case event
            dispatch(updateCallNote(caseActivityId, note, duration, description));

            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

            // reset call card
            resetCallCard({ dispatch, caseId });

            dispatch({ type: types.UPDATE_NOTE_SUCCESS });
        } catch (e) {
            dispatch({ type: types.UPDATE_NOTE_FAIL });
            dispatch(setSnackAction(e.message, "There was an error updating the note"));
        }
    };
}

export function saveInternalNote({ caseActivityId, noteText, description, caseTimeEventGroupIds }) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { userProfile, users } = userState;
        const { userId } = userProfile;
        const contractId = c?.caseSummary?.contractId;

        const saveInternalNoteAsync = async () => {
            try {
                dispatch({ type: types.SAVE_INTERNAL_NOTE_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // save internal note
                if (caseActivityId) {
                    const response = await caseActivityService.updateInternalNote({
                        noteText,
                        caseActivityId,
                        userId,
                        caseId,
                        description,
                    });

                    dispatch(setSnackAction("Succesfully updated Internal Note!", "success"));

                    dispatch(updateInternalNote(caseActivityId, noteText, response.data, description));
                } else {
                    const response = await caseActivityService.addInternalNote({
                        contractId,
                        caseId,
                        userId,
                        noteText,
                        description,
                        caseTimeEventGroupIds,
                    });
                    reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups, userId]);

                    dispatch(setSnackAction("Succesfully added Internal Note!", "success"));

                    dispatch(
                        addCaseEventAction(caseId, {
                            caseActivityId: response.data.activityId,
                            adviserId: userId,
                            itemType: caseActivityTypes.INTERNAL_NOTE,
                            eventTime: new Date(),
                            text: noteText,
                            direction: 2,
                            person: Object.values(users).filter((u) => u.userId === userId)[0].name,
                            duration: response.data.duration,
                            activityDescription: description,
                        })
                    );

                    dispatch(incrementEventCount(caseId));
                }

                dispatch(setInternalNoteText("", caseId));
                dispatch(
                    setCaseViewStateAction(caseId, {
                        ...c.viewState,
                        activityManage: null
                    })
                );
                dispatch(setInternalNoteCaseActivityId(null, caseId));
                dispatch(setInternalNoteDescription({ description: "", caseId }));

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                dispatch({ type: types.SAVE_INTERNAL_NOTE_SUCCESS });
            } catch (e) {
                dispatch({ type: types.SAVE_INTERNAL_NOTE_FAIL });
                throw new Error("There was an error saving the internal note");
            }
        };

        try {
            await saveInternalNoteAsync();
        } catch (e) {
            dispatch(setSnackAction(e.message, "error"));
        }
    };
}

export function saveDraft({ editorHtml, setLastModifiedDate, setLastModifiedBy, isAutoSave, caseId }) {
    return async (dispatch, getState) => {
        const { caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const c = caseState.cases[caseId];
        const draftId = c.draftEmail?.id;

        const { sending, savingDraft } = c.reply;

        if (sending || savingDraft)
            return;
        
        try {
            dispatch({ type: types.SAVE_DRAFT_REQUEST });

            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            const { data: saveDraftResponse } = await emailDraftService.saveDraft(
                buildSaveDraftEmailRequest({
                    caseId,
                    draftId,
                    content: editorHtml,
                    reply: c.reply,
                })
            );
           
            setLastModifiedDate(saveDraftResponse.emailDraft?.lastModified);
            setLastModifiedBy(saveDraftResponse.emailDraft?.lastModifiedByName);

            dispatch(updateCaseDraftEmail(saveDraftResponse.emailDraft));
            if (saveDraftResponse.uploadedAttachments.length)
                dispatch(
                    setReplyAttachmentAzureFileIdsAction({
                        caseId,
                        uploadedAttachments: saveDraftResponse.uploadedAttachments,
                    })
                );
            dispatch(setEditingDraft({ caseId, isEditingDraft: true }));
            if (!isAutoSave)
                dispatch(setSnackAction("Draft saved successfully.", "success"));

            dispatch({ type: types.SAVE_DRAFT_SUCCESS });
        } catch (e) {
            dispatch({ type: types.SAVE_DRAFT_FAIL });
            dispatch(setSnackAction("There was an error saving the draft", "error"));
        }
    };
}

export function discardUnallocatedCaseTime({ caseId }) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { userProfile } = userState;
        const { userId } = userProfile;

        try {
            dispatch({ type: types.DISCARD_UNALLOCATED_TIME_REQUEST });

            // save activities
            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            // discard unallocated time
            await unallocatedCaseTimeService.discardUnallocatedCaseTime({
                caseId,
                userId,
            });

            dispatch(setSnackAction("Successfully discarded time", "success"));

            dispatch({ type: types.DISCARD_UNALLOCATED_TIME_SUCCESS });
        } catch (e) {
            dispatch({ type: types.DISCARD_UNALLOCATED_TIME_FAIL });
            dispatch(setSnackAction("There was an error discarding your case time", "error"));
        }
    };
}

export function assignUnallocatedCaseTimeToUserActivity({ caseId, userActivityTypeId, caseTimeEventIds, description }) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseTimerReducer: caseTimerState, caseReducer: caseState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { userProfile } = userState;
        const { userId } = userProfile;
        const c = caseState.cases[caseId];
        const contractId = c?.caseSummary?.contractId;

        try {
            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_REQUEST,
            });

            // save activities
            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            // assign unallocated time
            await unallocatedCaseTimeService.assignUnallocatedCaseTime({
                caseId,
                userActivityTypeId,
                caseTimeEventIds,
                userId,
                description,
            });

            dispatch(setSnackAction("Successfully assigned time", "success"));

            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_SUCCESS,
            });

            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
        } catch (e) {
            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_FAIL,
            });
            dispatch(setSnackAction("There was an error assigning your case time", "error"));
        }
    };
}

export function assignUnallocatedCaseTimeToMiscActivity({ unallocatedCaseTimeEventIds, caseId }) {
    return async (dispatch, getState) => {
        const { caseTimerReducer: caseTimerState, caseReducer: caseState } = getState();
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const c = caseState.cases[caseId];
        const contractId = c?.caseSummary?.contractId;

        try {
            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_REQUEST,
            });

            // save activities
            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            // assign unallocated time
            await caseActivityService.saveRetrospectiveMiscActivity(unallocatedCaseTimeEventIds, caseId);

            dispatch(setSnackAction("Successfully saved activity", "success"));

            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_SUCCESS,
            });

            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

        } catch (e) {
            console.error(e);
            dispatch({
                type: types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_FAIL,
            });
            dispatch(setSnackAction(typeof e === "string" ? e : "There was an error saving your activity", "error"));
        }
    };
}

export function holdUnallocatedCaseTime({ accountId, description, caseTimeEventIds, caseId }) {
    return async (dispatch, getState) => {
        const { caseTimerReducer: caseTimerState, userReducer: userState } = getState();
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { userProfile } = userState;
        const { userId } = userProfile;

        try {
            dispatch({ type: types.HOLD_UNALLOCATED_TIME_REQUEST });

            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            await caseTimeEventGroupService.addCaseTimeEventGroup({
                accountId,
                description,
                caseTimeEventIds,
                caseId,
                userId,
            });
            reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups, userId]);
            dispatch(setSnackAction("Successfully saved case time", "success"));

            dispatch({ type: types.HOLD_UNALLOCATED_TIME_SUCCESS });
        } catch (e) {
            console.error(e);
            dispatch({ type: types.HOLD_UNALLOCATED_TIME_FAIL });
            dispatch(setSnackAction("There was an error saving the case time", "error"));
        }
    };
}

export function getUnallocatedCaseTime({ caseId }) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { userProfile } = userState;
        const { userId } = userProfile;

        try {
            dispatch({ type: types.GET_UNALLOCATED_TIME_REQUEST });

            // set backdrop
            dispatch(setBackdropOpen(true));

            // save activities
            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            // get unallocated time
            const response = await unallocatedCaseTimeService.getUnallocatedCaseTime({
                caseId,
                userId,
            });

            dispatch(setBackdropOpen(false));

            // set request status, unallocated seconds and case time event ids
            dispatch({
                type: types.GET_UNALLOCATED_TIME_SUCCESS,
                payload: {
                    seconds: response.data.seconds,
                    caseTimeEventIds: response.data.caseTimeEventIds,
                },
            });
        } catch (e) {
            dispatch({ type: types.GET_UNALLOCATED_TIME_FAIL });
            dispatch(setSnackAction("There was an error getting your unallocated time for this case", "error"));
        }
    };
}

export function saveTimerExpired() {
    return async (dispatch, getState) => {
        const { caseTimerReducer: caseTimerState } = getState();
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;

        try {
            dispatch({ type: types.SAVE_TIMER_EXPIRED_REQUEST });

            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            dispatch({ type: types.SAVE_TIMER_EXPIRED_SUCCESS });
        } catch (e) {
            dispatch({ type: types.SAVE_TIMER_EXPIRED_FAIL });
            dispatch(setSnackAction("There was an error saving time events (save timer expired)", "error"));
        }
    };
}

export function saveCurrentMiscActivity({ caseTimeEventGroupIds }) {
    return async (dispatch, getState) => {
        const { caseTimerReducer: caseTimerState, caseReducer: caseState } = getState();
        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const contractId = c?.caseSummary?.contractId;

        try {
            dispatch({ type: types.ADD_CURRENT_MISC_ACTIVITY_REQUEST });

            await sendActivitiesToApi({
                dispatch,
                saveRequestStatus,
                currentActivity,
                activities,
            });

            await caseActivityService.saveCurrentMiscActivity({
                caseTimeEventGroupIds,
            });

            dispatch({ type: types.ADD_CURRENT_MISC_ACTIVITY_SUCCESS });
            dispatch({ type: types.RESET_MISC_ACTIVITY });
            reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups]);
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
        } catch (e) {
            console.error(e);
            dispatch({ type: types.ADD_CURRENT_MISC_ACTIVITY_FAIL });
            dispatch(setSnackAction(e?.message || "There was an error adding the current misc activity", "error"));
        }
    };
}

export function addChatAndNote({
    activeChat,
    contactId,
    contactName,
    externalContactId,
    accountId,
    note,
    caseIsChargeable,
    isAdvisor,
    description,
    caseTimeEventGroupIds,
}) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { userProfile } = userState;
        const { userId } = userProfile;
        const caseActivityIds = c.caseActivityIds || [];
        const contractId = c?.caseSummary?.contractId;

        const addChatAndNoteAsync = async () => {
            try {
                // set saving = true
                dispatch({ type: types.ADD_CALL_AND_NOTE_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // add call and note
                const response = await caseActivityService.saveChatAndNote({
                    caseId,
                    activeChat,
                    contactId,
                    externalContactId,
                    accountId,
                    contractId,
                    userId,
                    note,
                    caseIsChargeable,
                    description,
                    caseTimeEventGroupIds,
                });

                reactQueryClient.invalidateQueries([queryKeys.caseTimeEventGroups, userId]);

                // post message to flex
                if (isAdvisor)
                    window.frames[0].postMessage(
                        {
                            event: "callNoteAdded",
                            taskSid: activeChat.taskSid,
                            caseId,
                        },
                        "*"
                    );

                // set snack
                dispatch(setSnackAction("Successfully saved Chat and Note!", "success"));

                // add comined case event
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.callActivityId,
                        adviserId: userId,
                        itemType: caseActivityTypes.CHAT_WITH_NOTE,
                        eventTime: response.data.start,
                        issue: note.issue,
                        advice: note.advice,
                        action: note.action,
                        direction: 0,
                        person: contactName,
                        duration: response.data.duration,
                        isChargeable: response.data.isChargeable,
                        activityDescription: description,
                    })
                );

                // adjust case minutes if blurDuration
                if (response.data.blurDuration > 0)
                    dispatch(setAdjustCaseMinutes(caseId, response.data.blurDuration / 60));

                // update chargeable time
                if (response.data.isChargeable) {
                    const chargeableSeconds = response.data.callDuration + response.data.noteDuration;
                    dispatch(
                        updateCaseChargeableTime({
                            caseId,
                            delta: chargeableSeconds,
                        })
                    );
                }

                // increment event count
                dispatch(incrementEventCount(caseId));

                // set caseActivityIds (call and note)
                dispatch(setCaseActivityIds([...caseActivityIds, response.data.callActivityId, response.data.noteActivityId], caseId));

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                // reset call card
                resetCallCard({ dispatch, caseId });

                dispatch({ type: types.ADD_CALL_AND_NOTE_SUCCESS });
            } catch (e) {
                dispatch({ type: types.ADD_CALL_AND_NOTE_FAIL });
                throw new Error("There was an error saving the call and note");
            }
        };

        try {
            await addChatAndNoteAsync();
        } catch (e) {
            dispatch(setSnackAction(e.message, "error"));
        }
    };
}

export function addChat({
    activeChat,
    contactId,
    externalContactId,
    accountId,
    caseIsChargeable,
    isAdvisor,
    contactName
}) {
    return async (dispatch, getState) => {
        const { userReducer: userState, caseReducer: caseState, caseTimerReducer: caseTimerState } = getState();

        const { saveRequestStatus, currentActivity, activities } = caseTimerState;
        const { currentCaseId: caseId } = caseState;
        const c = caseState.cases[caseId];
        const { userProfile } = userState;
        const { userId } = userProfile;
        const caseActivityIds = c.caseActivityIds || [];
        const contractId = c?.caseSummary?.contractId;

        const addChatAsync = async () => {
            try {
                dispatch({ type: types.ADD_CALL_REQUEST });

                // save activities
                await sendActivitiesToApi({
                    dispatch,
                    saveRequestStatus,
                    currentActivity,
                    activities,
                });

                // add chat
                const response = await caseActivityService.saveChat({
                    caseId,
                    conversationId: activeChat.conversationId,
                    contactId,
                    externalContactId,
                    userId,
                    accountId,
                    contractId,
                    caseIsChargeable,
                });

                // set snack
                dispatch(setSnackAction("Successfully saved Chat!", "success"));

                // add case event
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.activityId,
                        adviserId: userId,
                        itemType: caseActivityTypes.CHAT,
                        eventTime: response.data.start,
                        direction: 0,
                        person: contactName,
                        duration: 0,
                        isChargeable: response.data.isChargeable,
                    })
                );

                // increment event count
                dispatch(incrementEventCount(caseId));

                // set case activity ids
                dispatch(setCaseActivityIds([...caseActivityIds, response.data.activityId], caseId));                               

                //  post message to flex
                if (isAdvisor)
                    window.frames[0].postMessage(
                        {
                            event: "callNoteAdded",
                            taskSid: activeChat.taskSid,
                            caseId,
                        },
                        "*"
                    );

                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

                resetCallCard({ dispatch, caseId });

                dispatch({ type: types.ADD_CALL_SUCCESS });
            } catch (e) {
                dispatch({ type: types.ADD_CALL_FAIL });
                throw new Error("There was an error saving the chat");
            }
        };

        try {
            await addChatAsync();
        } catch (e) {
            dispatch(setSnackAction(e.message, "error"));
        }
    };
}
