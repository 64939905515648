import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import axios from "../../plugins/axios";

const useConversation = (caseActivityId) =>
    useQuery([queryKeys.conversation, caseActivityId], async () => {
        let response = await axios.get(`${process.env.REACT_APP_CASENEST_API_URL}/conversation/${caseActivityId}`);
        return response.data;
    }, {
        enabled: !!caseActivityId,
    });

export default useConversation;
