import * as types from "../constants";
import OpenAI from "openai";
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const openai = new OpenAI({
    apiKey: 'sk-svcacct-vdlHz4_265OSRGmUTVRKj0ICcTpeiktLBISqyzyWSLNDrpgIN_klgC4NcXp47VYT3BlbkFJPChFwrAoKJpA9-9p-kG2gOuonm-37u7JgyoS2cAO3N_5_YZfrqVaqI2SPLUbcOQA',
    dangerouslyAllowBrowser: true
});

const executeRun = async (dispatch, caseId, getState) => {    

    const { cases } = getState().caseReducer;

    const aiState = cases[caseId].aiState;

    const { threadId, assistantId } = aiState;

    const run = await openai.beta.threads.runs.create(
        threadId,
        {
            assistant_id: assistantId,
        }
    );

    let intervalId = setInterval(async () => {

        const updatedRun = await openai.beta.threads.runs.retrieve(threadId, run.id);

        if (updatedRun.status === "completed") {
            clearInterval(intervalId);

            dispatch({
                type: types.SET_CASE_AI_STATE,
                caseId,
                payload: {
                    processing: false,
                    alert: true,
                    used: true
                }
            });
        }
        else if (updatedRun.status !== "queued" && updatedRun.status !== "in_progress") {
            console.error(updatedRun);

            dispatch({
                type: types.SET_CASE_AI_STATE,
                caseId,
                payload: {
                    processing: false,
                    alert: true                    
                }
            });

            dispatch({
                type: types.SET_SNACK,
                snack: {
                    message: `something went wrong : ${updatedRun.last_error?.message}`,
                    severity: 'error'
                }
            });
        }

        await getMessages(dispatch, caseId, getState);

    }, 2000);

};

const getMessages = async (dispatch, caseId, getState) => {

    const { cases } = getState().caseReducer;

    const aiState = cases[caseId].aiState;

    const { threadId, files, processing, postedMessageIds } = aiState;

    const threadMessages = await openai.beta.threads.messages.list(threadId);

    let existingFileIds = files.map(f => f.id);    

    let newFiles = [...files];

    for (const m of threadMessages.data) {
        for (const attachment of m.attachments) {
            if (!existingFileIds.includes(attachment.file_id)) {
                try {
                    let file = await openai.files.retrieve(attachment.file_id);
                    newFiles.push(file);
                }
                catch (err) {
                    console.error(attachment.file_id, err);
                }
            }
        }
        for (const entry of m.content) {
            if (entry.type === "image_file") {
                let fileId = entry.image_file.file_id;

                if (!existingFileIds.includes(fileId)) {
                    try {
                        let file = await openai.files.retrieve(fileId);
                        newFiles.push(file);
                    }
                    catch (err) {
                        console.error(fileId, err);
                    }
                }
            }

        }
    }

    if (!processing) {
        let newMessages = threadMessages.data.filter(m => !postedMessageIds.includes(m.id));

        let dtos = newMessages.map(m => ({
            threadId,
            caseId,
            createdAt: m.created_at,
            role: m.role,
            messageId: m.id,
            assistantId: m.assistant_id,
            content: m.content[0]?.text?.value,
            files: newFiles.filter(f => m.attachments.map(a => a.file_id).includes(f.id)).map(f => ({ key: f.id, value: f.filename }))
        }));

        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                postedMessageIds: threadMessages.data.map(m => m.id)
            }
        });

        axios.put(`${apiUrl}/aithread/addmessages/${caseId}/${threadId}`, dtos);
    }    

    dispatch({
        type: types.SET_CASE_AI_STATE,
        caseId,
        payload: {
            messages: threadMessages.data,
            files: newFiles
        }
    });
}

const createThread = async (dispatch, caseId) => {
    const thread = await openai.beta.threads.create();

    dispatch({
        type: types.SET_CASE_AI_STATE,
        caseId,
        payload: { threadId: thread.id }
    });

    return thread.id;
}


export const aiActionCreators = {

    addAIMessage: (caseId, currentMessage, withRun) => async (dispatch, getState) => {

        const { cases } = getState().caseReducer;

        const aiState = cases[caseId].aiState;

        let { threadId } = aiState;

        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                processing: true,                
            }
        });

        if (!threadId) {

            threadId = await createThread(dispatch, caseId);
        }

        await openai.beta.threads.messages.create(
            threadId,
            {
                role: "user",
                content: currentMessage
            }
        );        

        await getMessages(dispatch, caseId, getState);

        if (withRun) {
            await executeRun(dispatch, caseId, getState);
        }

        else {
            dispatch({
                type: types.SET_CASE_AI_STATE,
                caseId,
                payload: {
                    processing: false
                }
            });
        }
    },

    postFile: (caseId, path, filename, fileType) => async (dispatch, getState) => {

        const { cases } = getState().caseReducer;

        const aiState = cases[caseId].aiState;

        let { threadId } = aiState;

        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                processing: true
            }
        });

        if (!threadId) {
            threadId = await createThread(dispatch, caseId);
        }

        let response = await fetch(path);
        
        let data = await response.blob();
        let f = new File([data], filename, { type: fileType });

        console.log({f});

        try {
            const file = await openai.files.create({
                file: f,
                purpose: "assistants"
            });            

            if (fileType.startsWith("image")) {
                await openai.beta.threads.messages.create(
                    threadId,
                    {
                        role: "user",
                        content: [
                            {
                                type: "image_file",
                                image_file: {
                                    file_id: file.id
                                }
                            }
                        ]
                    }
                );
            }
            else {
                await openai.beta.threads.messages.create(
                    threadId,
                    {
                        role: "user",
                        content: "here is a file",
                        attachments: [
                            {
                                file_id: file.id,
                                tools: [
                                    { "type": "file_search" },
                                    { "type": "code_interpreter" }
                                ]
                            }
                        ]
                    }
                );
            }            
        }
        catch (err) {
            console.error(err);

            dispatch({
                type: types.SET_SNACK,
                snack: {
                    message: err.toString(),
                    severity: 'error'
                }
            });
        }
        finally {
            dispatch({
                type: types.SET_CASE_AI_STATE,
                caseId,
                payload: {
                    processing: false
                }
            });

            await getMessages(dispatch, caseId, getState);
        }       

    },

    checkMessages: (caseId) => async (dispatch, getState) => {        

        await getMessages(dispatch, caseId, getState);

    },

    runAIThread: (caseId) => async (dispatch, getState) => {

        const { cases } = getState().caseReducer;

        const aiState = cases[caseId].aiState;

        await executeRun(dispatch, caseId, aiState);
    },

    setAssistantId: (caseId, assistantId) => async (dispatch) => {
        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: { assistantId }
        });
    },


    reset: (caseId) => async (dispatch) => {
        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: { threadId: null, messages: [] }
        });
    },

    setThumbsUp: (caseId, messageId, thumbsUp) => async (dispatch, getState) => {

        if (!thumbsUp)
            dispatch({
                type: types.SET_SNACK,
                snack: {
                    message: "I'm sorry to hear you weren't satisfied. Please share specific details on what could be improved in my response.",
                    severity: "warning"
                }
            });

        const { cases } = getState().caseReducer;

        const aiState = cases[caseId].aiState;

        const { thumbsUpIds, thumbsDownIds } = aiState;

        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                thumbsUpIds: thumbsUp ? [ ...thumbsUpIds, messageId] : thumbsUpIds.filter(id => id !== messageId),
                thumbsDownIds: !thumbsUp ? [ ...thumbsDownIds, messageId] : thumbsDownIds.filter(id => id !== messageId)
            }
        });

        axios.post(`${apiUrl}/aithread/SetThumbsUp/${messageId}/${thumbsUp}`);
    },

    clearAlert: (caseId) => async (dispatch) => {
        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                alert: false
            }
        });
    },

    setCurrentMessage: (caseId, currentMessage) => async (dispatch) => {
        dispatch({
            type: types.SET_CASE_AI_STATE,
            caseId,
            payload: {
                currentMessage
            }
        });
    }
};

export default aiActionCreators;