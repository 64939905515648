import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useProductRecommendations from "../hooks/queries/useProductRecommendations";
import queryKeys from "../constants/queryKeys";
import reactQueryClient from "../reactQueryClient";

import {
    CardContent,
    Card,
    Typography,
    Grid,
    Box,
} from "@material-ui/core";
import BulbIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ProductRecommendationsPanel from "./ProductRecommendationPanel";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(6),
        backgroundColor : '#313033',
    },
    icon: {
        padding: '3px',
        paddingBottom: 0,
        height : 'auto',
        border: '1px solid #cbb1ff',
        borderRadius: '3px',
        color: '#cbb1ff',
        marginRight: '10px',
    }
}));

const ProductRecommendationsCard = ({ account, adviceTypeId, caseId }) => {    

    const { data: recommendations } = useProductRecommendations(account.accountId, adviceTypeId, caseId);
    
    const classes = useStyles();

    if (!recommendations || recommendations.length === 0)
        return null;    

    const refresh = () => {
        reactQueryClient.invalidateQueries([queryKeys.productRecommendations, account.accountId, adviceTypeId, caseId]);
    }    

    const isSingle = recommendations.length === 1; 

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box display="flex">
                    <Box>
                        <div className={classes.icon}>
                            <BulbIcon />
                        </div>
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom style={{color: '#cbb1ff'}}>
                            Product Recommendations
                        </Typography>
                        <Typography style={{ color: '#cfcecf' }}>Recommendations are based on case history data.  Record the client's response using the options provided.</Typography>
                    </Box>
                </Box>
                
                <Grid container spacing={3}>
                    {recommendations.map(product =>
                        <ProductRecommendationsPanel key={product.id} product={product} refresh={refresh} isSingle={isSingle} account={account} id={product.id} />                     
                    )}
                </Grid>                
            </CardContent>            
        </Card>
    );
};

export default ProductRecommendationsCard;
