import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getCalls = ({ userId, accountId }) => {
    return axios.get(`${apiUrl}/getcalls/${userId}/${accountId}`);
};

const getChats = ({ userId, accountId }) => {
    return axios.get(`${apiUrl}/getchats/${userId}/${accountId}`);
};

const callService = { getCalls, getChats };

export default callService;
