import React, { useCallback, useEffect, useRef, useState } from "react";
import { format, isSameDay } from "date-fns";
import { connect, useDispatch } from "react-redux";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import {
    Person,    
    Replay as UndoIcon,
    Search,
} from "@material-ui/icons";
import {
    setCallNoteChat as setCallNoteChatAction,
    setCallNoteContact as setCallNoteContactAction,
    setCallNoteDescription as setCallNoteDescriptionAction,
    setCallNoteDescriptionFocus as setCallNoteDescriptionFocusAction,
    setCallNoteOutbound as setCallNoteOutboundAction,
} from "../redux/actions/caseActions";
import { setCallNoteInteracted } from "../redux/actions/caseTimerActions";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import { emptyChat, emptyCallNote } from "../constants/emptyActivities";
import { addChat, addChatAndNote, updateNote } from "../redux/actions/thunks";
import { setSnackAction } from "../redux/actions/snackActions";
import callNoteModes from "../constants/callNoteModes";
import contactService from "../services/contactService";
import callService from "../services/callService";
import AllocatedCallTimeDialog from "../components/AllocatedCallTimeDialog";
import useCaseExternalContacts from "../hooks/queries/useCaseExternalContacts";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants";
import CaseTimeEventGroupsDialog from "../components/dialogs/CaseTimeEventGroupsDialog";
import useCaseTimeEventGroupsForUserAndAccount from "../hooks/queries/useCaseTimeEventGroupsForUserAndAccount";

const useStyles = makeStyles((theme) => ({
    description: {
        width: "100%",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    noteText: {
        width: "100%",
    },
    outgoingCallIcon: {
        color: blue[600],
    },
    callMenu: {
        borderRadius: "0px",
        "& ul": {
            padding: "0px",
        },
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    textTitle: {
        marginTop: theme.spacing(2),
    },
    activeChat: {
        backgroundColor: grey[100],
    },
}));

const Chat = ({ chat, activeChat = false }) => {
    const classes = useStyles();

    let startDate = new Date(chat.dateCreated);

    return (
        <Box
            display="flex"
            my={activeChat ? 3 : 0}
            p={activeChat ? 3 : 0}
            width="100%"
            className={activeChat ? classes.activeChat : ""}
        >
            <Box display="flex" flexGrow={1} width="100%">
                <Box display="flex" flexGrow={1} justifyContent="flex-start">            
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        mr={!activeChat ? 6 : 0}
                    >
                        {
                            chat.contactName &&
                            <Typography variant="body1">{chat.contactName || "Unknown Contact"} - {chat.accountName || "Unknown Account"}</Typography>
                        }
                        <Typography
                            variant={chat.contactName ? "body2" : "body1"}
                        >
                            {chat.from}
                        </Typography>
                    </Box>

                </Box>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">{!isSameDay(startDate, new Date()) && format(startDate, "dd/MM")} {format(startDate, "HH:mm")}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const activityTypes = {
    CALL: 0,
    CALL_AND_NOTE: 1,
};

const ChatCard = ({
    userId,
    close,
    setCallNoteChat,
    setCallNoteMode,
    setCallNoteContact,
    setCallNoteText,
    setCallNoteCaseActivityId,
    setCallNoteIssueFocus,
    setCallNoteAdviceFocus,
    setCallNoteActionFocus,
    setCallNoteOutbound,
    caseState,
    currentCallId,
    setSnack,
}) => {
    const classes = useStyles();

    const { currentCaseId: caseId, cases } = caseState;
   
    const dispatch = useDispatch();

    const c = cases[caseId];
    const accountId = c.account.accountId;
    const activeChat = c.callNote.chat || emptyChat;
    const mode = c.callNote.mode;
    const contact = c.callNote.contact;
    const note = c.callNote.text;
    const caseActivityId = c.callNote.caseActivityId;
    const caseActivityIds = c.caseActivityIds;
    const contractId = c.caseSummary.contractId;
    const caseIsChargeable = c.caseSummary.isChargeable;
    const saving = c.callNote.saving;
    const description = c.callNote.description;
    const [chats, setChats] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [matchingContacts, setMatchingContacts] = useState([]);
    const [contactQuery, setContactQuery] = useState("");
    const [contactsLoading, setContactsLoading] = useState(false);
    const [anchorElContact, setAnchorElContact] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const [allocatedCallTime, setAllocatedCallTime] = useState(null);
    const [activityType, setActivityType] = useState(null);
    const [externalContactSearchText, setExternalContactSearchText] = useState(null);

    console.log({
        callNote: c.callNote,
        currentCallId,
        activityType,
        contacts,
        chats,       
    });


    const {
        data: externalContacts,
        isError: isExternalContactsError,
        isLoading: isExternalContactsLoading,
        error: externalContactsError,
    } = useCaseExternalContacts({ caseId, externalContactSearchText });

    const callNoteRef = useRef(null);

    const { hasRole } = useAuth();
    const hasFlexRole = hasRole(userRoles.LEGAL_ADVISOR);

    const [heldTimeOpen, setHeldTimeOpen] = useState(false);
    const { data: caseTimeEventGroups } = useCaseTimeEventGroupsForUserAndAccount({ userId, accountId });   

    const getChats = useCallback(async () => {
        try {
            const response = await callService.getChats({ userId, accountId });
            setChats(response.data);
        } catch (e) {
            console.error(e);
        }
    }, [userId, accountId]);

    const handleChangeChat = (chat) => {
        setCallNoteChat(chats.filter((c) => c.conversationId === chat.conversationId)[0], caseId);

        setAnchorEl(null);
    };

    const handleContactSearch = async (node) => {
        try {
            setContactsLoading(true);
            setAnchorElContact(node);
            contactQuery && setExternalContactSearchText(contactQuery);
            const response = await contactService.getContacts({
                fullName: contactQuery,
                accountId,
            });

            setContacts(response.data.contacts);
        } catch (e) {
            console.error(e);
        } finally {
            setContactsLoading(false);
        }
    };

    const handleEnterKeyPress = (e, node) => {
        if (e.key === "Enter") {
            handleContactSearch(node);
        }
    };

    const handleClickContact = (c) => {
        setCallNoteContact(c, caseId);
        setAnchorElContact(null, caseId);
    };    

    const handleClickSaveChatAndNote = async () => {
        if (!description.length > 0) {
            setSnack("Call note must have a description", "warning")
            return;
        }

        setActivityType(activityTypes.CALL_AND_NOTE);        

        handleSaveChatAndNote();
    };

    const handleClickSaveChat = async () => {
        setOpen(false);

        setActivityType(activityTypes.CALL);        

        handleSaveChat();
    };

    const handleSaveChatAndNote = (caseTimeEventGroupIds = []) => {
        if (caseTimeEventGroups?.length && !heldTimeOpen && !caseActivityId) {
            setHeldTimeOpen(true);
            return;
        }
        setHeldTimeOpen(false);

        dispatch(
            addChatAndNote({
                activeChat,
                contactId: contact.contactId,
                contactName: contact.name,
                externalContactId: contact.externalContactId,
                accountId,
                note,
                caseIsChargeable,
                isAdvisor: hasFlexRole,
                description,
                caseTimeEventGroupIds,
            })        
        );        
    };

    const handleSaveChat = () => {
        dispatch(
            addChat({
                activeChat,
                contactId: contact.contactId,
                contactName: contact.name,
                externalContactId: contact.externalContactId,
                accountId,
                contractId,
                caseIsChargeable,
                isAdvisor: hasFlexRole,
            })
        );
    };

    const handleUpdateCallNote = async () => {
        if (!description.length > 0) {
            setSnack("Call note must have a description", "warning");
            return;
        }

        dispatch(
            updateNote({
                caseActivityId,
                note,
                description,
            })
        );
    };

    const handleReset = () => {
        setCallNoteText(emptyCallNote, caseId);
        setCallNoteChat(emptyChat, caseId);
        close();
        setCallNoteCaseActivityId(null, caseId);
        setCallNoteMode(callNoteModes.CALL, caseId);
    };

    const getPhoneString = () => {
        if (!matchingContacts.length)
            return contact.phone || contact.mobile || "No number available";
        else {
            let phone = contact.phone;
            if (phone) {
                phone = phone.replace("+44", "").replace(" ", "");
                if (phone.startsWith("0")) phone = phone.substring(1);
                if (activeChat.from.includes(phone)) return contact.phone;
            }

            let mobile = contact.mobile;
            if (mobile) {
                mobile = mobile.replace("+44", "").replace(" ", "");
                if (mobile.startsWith("0")) mobile = mobile.substring(1);
                if (activeChat.from.includes(mobile)) return contact.mobile;
            }

            return contact.phone || contact.mobile || "No number available";
        }
    };  

    const handleClickLogChat = () => {
        dispatch(setCallNoteInteracted());
        setCallNoteMode(callNoteModes.NOTE, caseId);
    };

    const handleClickSaveWithoutNote = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAllocatedCallTime(null);
        setActivityType(null);
    };

    const handleConfirm = () => {
        switch (activityType) {
            case activityTypes.CALL:
                handleSaveChat();
                break;

            case activityTypes.CALL_AND_NOTE:
                handleSaveChatAndNote();
                break;

            default:
                return;
        }

        handleClose();
    };

    const handleSaveFromHeldTimeDialog = (_e, caseTimeEventGroupIds = []) => {
        handleSaveChatAndNote(caseTimeEventGroupIds);
    };

    const handleCloseHeldTimeDialog = () => {
        setHeldTimeOpen(false);        
    };

    useEffect(() => {
        
        if (currentCallId && chats.length && !activeChat.conversationId) {
            setCallNoteOutbound(false, caseId);
            setCallNoteChat(chats.find((c) => c.conversationId === currentCallId), caseId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCallId, chats, caseId, activeChat]);

    useEffect(() => {
        getChats();
    }, [getChats]);

    useEffect(() => {

        const getMatchingContacts = async () => {
            try {
                const response = await contactService.getContacts({
                    accountId,
                    phone: activeChat.from,
                    pageNum: 1,
                    pageSize: 100,
                });

                setMatchingContacts(...response.data.contacts, externalContacts);
            } catch (e) {
                console.error(e);
            }
        };
        if (activeChat.conversationId) {
            getMatchingContacts();
        }
    }, [accountId, activeChat, externalContacts]);

    useEffect(() => {
        if (mode !== callNoteModes.NOTE) {
            if (matchingContacts.length === 1)
                setCallNoteContact(matchingContacts[0], caseId);
            else if (matchingContacts.length > 1 && activeChat.contactId)
                setCallNoteContact(matchingContacts.find((c) => c.contactId === activeChat.contactId), caseId);
            else
                setCallNoteContact(null, caseId);
        }
    }, [activeChat.contactId, caseId, matchingContacts, mode, setCallNoteContact]);

    useEffect(() => {
        if (mode === callNoteModes.NOTE)
            callNoteRef.current.focus();
    }, [mode]);

    return (
        <React.Fragment>
            <Card className={classes.card}>
                <CardContent>
                    {mode === callNoteModes.CALL && (
                        <React.Fragment>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Chat
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={handleReset}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {activeChat.conversationId ? (
                                <Box display="flex" flexDirection="column">
                                    <Chat chat={activeChat} activeChat />
                                    <Box mt={3}>
                                        <Typography variant="caption">
                                            Not the right chat?
                                        </Typography>
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => setAnchorEl(e.currentTarget.parentNode)}
                                        >
                                            Change Chat
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box>                                    
                                    <Box
                                        my={3}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => setAnchorEl(e.currentTarget.parentNode)}
                                        >
                                            Choose Chat
                                        </Button>                                        
                                    </Box>
                                </Box>
                            )}
                            <Typography variant="h6">Contact</Typography>
                            {activeChat.conversationId &&
                                !contact &&
                                matchingContacts.length > 1 && (
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            Looks like multiple contacts matched
                                            this number. Please search for the
                                            right contact below
                                        </Typography>
                                    </Box>
                                )}
                            {activeChat.conversationId &&
                                !contact &&
                                matchingContacts.length === 0 && (
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            No contacts match this number.
                                            Please add a new contact, or search
                                            for an existing one
                                        </Typography>
                                    </Box>
                                )}
                            {!activeChat.conversationId && (
                                <Box my={3}>
                                    <Typography variant="caption">
                                        Please select a chat before assigning a contact
                                    </Typography>
                                </Box>
                            )}
                            {contact && (
                                <Box display="flex" justifyContent="center">
                                    <Chip
                                        icon={<Person />}
                                        label={getPhoneString()}                                        
                                    />                                    
                                </Box>
                            )}
                            {(activeChat.conversationId && !contact) && (
                                    <Box display="flex" justifyContent="center">
                                        <FormControl>
                                            <InputLabel htmlFor="call-contact-search">
                                                Contact
                                            </InputLabel>
                                            <Input
                                                id="call-contact-search"
                                                type="text"
                                                value={contactQuery}
                                                onChange={(e) =>
                                                    setContactQuery(e.target.value)
                                                }
                                                onKeyPress={(e) =>
                                                    handleEnterKeyPress(
                                                        e,
                                                        e.currentTarget
                                                    )
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            onClick={(e) => handleContactSearch(e.currentTarget.parentNode.parentNode)}
                                                        >
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                )}
                            {contact && !activeChat.contactId && (
                                <Box>
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            Not the right contact?
                                        </Typography>
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => setCallNoteContact(null, caseId)}
                                        >
                                            Change Contact
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {activeChat.conversationId && contact && (
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        onClick={handleClickLogChat}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Log Chat
                                    </Button>
                                </Box>
                            )}
                            <Menu
                                className={classes.callMenu}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                            >
                                {!chats.filter((c) => c.conversationId !== activeChat.conversationId && !c.caseActivityIds.some((id) => caseActivityIds.includes(id))).length &&
                                    <MenuItem>No chats found</MenuItem>
                                }
                                {chats
                                    .filter((c) => c.conversationId !== activeChat.conversationId && !c.caseActivityIds.some((id) => caseActivityIds.includes(id)))
                                    .map((c) => (
                                        <MenuItem
                                            key={c.conversationId}
                                            onClick={() => handleChangeChat(c)}
                                        >
                                            <Chat chat={c} />
                                        </MenuItem>
                                    ))}
                            </Menu>
                            <Menu
                                anchorEl={anchorElContact}
                                keepMounted
                                open={Boolean(anchorElContact)}
                                onClose={() => setAnchorElContact(null)}
                            >
                                {!contactsLoading && contacts.length
                                    ? contacts
                                        .map((c) => (
                                            <MenuItem
                                                key={c.contactId}
                                                onClick={() =>
                                                    handleClickContact(c)
                                                }
                                            >
                                                {c.name}
                                            </MenuItem>
                                        ))
                                    : null}
                                {isExternalContactsError ? (
                                    <Typography>
                                        {externalContactsError?.message ||
                                            "Failed to load External Contacts"}
                                    </Typography>
                                ) : !isExternalContactsLoading &&
                                    externalContacts.length ? (
                                    externalContacts
                                        .map((c) => (
                                            <MenuItem
                                                key={c.externalContactId}
                                                onClick={() =>
                                                    handleClickContact(c)
                                                }
                                            >
                                                {"[Ext] " + c.name}
                                            </MenuItem>
                                        ))
                                ) : null}
                                {!isExternalContactsLoading &&
                                    !contactsLoading &&
                                    !externalContacts.length &&
                                    !contacts.length && (
                                        <MenuItem>No contacts found</MenuItem>
                                    )}

                                {(isExternalContactsLoading ||
                                    contactsLoading) && (
                                        <MenuItem>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                            >
                                                <CircularProgress />
                                            </Box>
                                        </MenuItem>
                                    )}
                            </Menu>
                        </React.Fragment>
                    )}

                    {mode === callNoteModes.NOTE && (
                        <React.Fragment>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Call Note
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={handleReset}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Issue
                            </Typography>
                            <TextField
                                inputRef={callNoteRef}
                                className={classes.noteText}
                                value={note.issue}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, issue: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteIssueFocus(true)}
                                onBlur={() => setCallNoteIssueFocus(false)}
                            />
                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Advice
                            </Typography>
                            <TextField
                                className={classes.noteText}
                                value={note.advice}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, advice: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteAdviceFocus(true)}
                                onBlur={() => setCallNoteAdviceFocus(false)}
                            />
                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Action
                            </Typography>
                            <TextField
                                className={classes.noteText}
                                value={note.action}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, action: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteActionFocus(true)}
                                onBlur={() => setCallNoteActionFocus(false)}
                            />
                            <TextField
                                className={classes.description}
                                value={description}
                                onChange={(e) => dispatch(setCallNoteDescriptionAction(e.target.value))}
                                onFocus={() => dispatch(setCallNoteDescriptionFocusAction(true))}
                                onBlur={() => dispatch(setCallNoteDescriptionFocusAction(false))}
                                variant="outlined"
                                label="Description"
                                inputProps={{
                                    maxLength: MAX_ACTIVITY_DESCRIPTION,
                                }}
                            />
                            <Box
                                my={3}
                                display="flex"
                                justifyContent="space-between"
                            >
                                {saving ? (
                                    <CircularProgress />
                                ) : caseActivityId ? (
                                    <Button
                                        onClick={handleUpdateCallNote}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Update Call Note
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            onClick={handleClickSaveWithoutNote}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save Chat without Note
                                        </Button>
                                        <Button
                                            onClick={handleClickSaveChatAndNote}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Create Chat Note
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Box>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Save call without note?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to save this call without a note?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSaveChat} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <AllocatedCallTimeDialog
                allocatedCallTime={allocatedCallTime}
                caseId={caseId}
                handleClose={() => setAllocatedCallTime(null)}
                handleConfirm={handleConfirm}
            />
            <CaseTimeEventGroupsDialog
                open={heldTimeOpen}
                onClose={handleCloseHeldTimeDialog}
                onConfirm={handleSaveFromHeldTimeDialog}
                confirmLabel="Save"
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    currentCallId: state.flexReducer.currentCallId,
});


const mapDispatchToProps = (dispatch) => ({
    setCallNoteContact: (contact, caseId) => dispatch(setCallNoteContactAction(contact, caseId)),
    setCallNoteOutbound: (outbound, caseId) => dispatch(setCallNoteOutboundAction(outbound, caseId)),
    setCallNoteChat: (call, caseId) => dispatch(setCallNoteChatAction(call, caseId)),
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatCard);
