import React, { useState, useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { Grid, Box } from '@material-ui/core';
import { useForm, Form } from "../helpers/useForm";
import { Controls } from "../helpers/Controls";
import { isStringEmptyOrWhitespaceOnly } from '../helpers/isStringEmptyOrWhitespaceOnly';

const isKeyAccountItems = [
    { id: 'unknown', title: 'Unknown' },
    { id: 'yes', title: 'Yes' },
    { id: 'no', title: 'No' },
]

const initialFValues = {
    accountId: 0,
    name: '',
    addressStreet: '',
    addressCity: '',
    addressCounty: '',
    addressPostCode: '',

    firstName: '',
    lastName: '',
    email: '',
    phone: '',

    partnershipNumber: '',
    partnershipId: '',
    isKeyAccount: 'Unknown'
}

const CreatePartnership = ({ configState, addOrEdit, recordForEdit, hideContactFields }) => {

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues);

    const [partnerships, setPartnershipsLocal] = useState([]);

    const [clicked, setClicked] = useState();

    const [submitClicked, setSubmitClicked] = useState();

    const handleAccountApproval = () => {
        setClicked(true);
        values.accountStatus = "Active";
    };

    const getPartnerships = useCallback(() => {
        setPartnershipsLocal(Object.values(configState.partnerships)
            .map(p => ({ ...p, toEdit: false, edited: false, toDelete: false, isNew: false }))
        );
    }, [configState.partnerships]);

    const validate = () => {

        let temp = {}
        temp.name = values.name ? "" : "Field is required";
        temp.partnershipId = values.partnershipId.length != 0 ? "" : "Partnership number is required";

        if (!hideContactFields) {
            temp.firstName = values.firstName.length > 40 ? "First name field cannot exceed 40 characters" : "";
        }

        if (!hideContactFields) {
            temp.lastName = values.lastName.length > 80 ? "Last name field cannot exceed 80 characters" : "";
        }

        if (!hideContactFields) temp.email = (/$^|.+@.+..+/).test(values.email) ? "" : "email entered is not valid";

        if (!hideContactFields && isStringEmptyOrWhitespaceOnly(values.firstName))
            temp.firstName = "First name cannot be blank."

        if (!hideContactFields && isStringEmptyOrWhitespaceOnly(values.lastName))
            temp.lastName = "Last name cannot be blank."


        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x == "");
    }

    const handleSubmit = async e => {

        setSubmitClicked(true);
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
        else {
            setSubmitClicked(false);
        }
    }

    useEffect(() => {

        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })

    }, [recordForEdit]);

    useEffect(() => {
        getPartnerships()
    }, [getPartnerships]);

    return (
        <Form>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.InputControl
                        name="name"
                        label="Name"
                        fieldMaxLength="255"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Controls.InputControl
                        label="Street"
                        name="addressStreet"
                        fieldMaxLength="255"
                        value={values.addressStreet}
                        onChange={handleInputChange}
                    />
                    <Controls.InputControl
                        label="City"
                        name="addressCity"
                        fieldMaxLength="40"
                        value={values.addressCity}
                        onChange={handleInputChange}
                    />
                    <Controls.InputControl
                        label="County"
                        name="addressCounty"
                        fieldMaxLength="80"
                        value={values.addressCounty}
                        onChange={handleInputChange}
                    />
                    <Controls.InputControl
                        label="Postcode"
                        name="addressPostCode"
                        fieldMaxLength="20"
                        value={values.addressPostCode}
                        onChange={handleInputChange}
                    />
                    {!hideContactFields &&
                        <Controls.InputControl
                            name="firstName"
                            label="First Name"
                            value={values.firstName}
                            onChange={handleInputChange}
                            error={errors.firstName}

                        />
                    }
                    {!hideContactFields &&
                        <Controls.InputControl
                            name="lastName"
                            label="Last Name"
                            value={values.lastName}
                            onChange={handleInputChange}
                            error={errors.lastName}
                        />
                    }
                    {!hideContactFields &&
                        <Controls.InputControl
                            name="email"
                            label="Email"
                            fieldMaxLength="320"
                            value={values.email == undefined ? '' : values.email.trim()}
                            onChange={handleInputChange}
                            error={errors.email}

                        />
                    }
                    {!hideContactFields &&
                        <Controls.InputControl
                            label="Phone"
                            name="phone"
                            fieldMaxLength="50"
                            value={values.phone}
                            onChange={handleInputChange}
                        />
                    }
                </Grid>
                <Grid item xs={6} container>
                    <Grid item xs={12}>
                        <Controls.RadioGroupControl
                            name="isKeyAccount"
                            label="Is Key Account"
                            values={values.isKeyAccount}
                            onChange={handleInputChange}
                            items={isKeyAccountItems}
                        />
                        <Controls.InputControl
                            label="Partnership Number"
                            name="partnershipNumber"
                            fieldMaxLength="40"
                            value={values.partnershipNumber}
                            onChange={handleInputChange}
                            error={errors.partnershipNumber}
                            disabled={hideContactFields}
                        />
                        <Controls.DDListControl
                            name="partnershipId"
                            label="Partnership"
                            value={values.partnershipId}
                            onChange={handleInputChange}
                            options={partnerships}
                            error={errors.partnershipId}
                            disabled={hideContactFields}

                        />
                        <div>
                            <Controls.ButtonControl
                                onClick={handleSubmit}
                                name="submitBtn"
                                disabled={submitClicked}
                                text="Submit" />   {clicked ? "Click submit to store the Partner Approval !" : null}
                        </div>
                        <div>
                            {
                                hideContactFields && values.accountStatus === "Pending" &&
                                <Controls.ButtonControl
                                    color="default"
                                    onClick={() => handleAccountApproval()}
                                    disabled={clicked}
                                    text="Approve Pending Partner Member Request"
                                />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
                        <Box display="flex" justifyContent="flex-end">
                            {!hideContactFields &&
                                <Controls.ButtonControl
                                    text="Reset"
                                    color="default"
                                    onClick={resetForm} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
}

export default connect(state => ({ configState: state.configReducer }))(CreatePartnership);