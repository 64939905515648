import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    TextField,
    Box,
    MenuItem,
    Grid,
    Chip,
    Button,
    makeStyles,
} from "@material-ui/core";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import {
    setMiscActivityDescription,
    setMiscActivityTypeId,
    setMiscActivityDate,
    setMiscActivityStartTime,
    setExistingMiscActivityFiles,
    setMiscActivityFilesToDelete,
    setMiscActivityHours,
    setMiscActivityMinutes,
    setMiscActivityNotes,
    setMiscActivityAttachmentsAction,
    setMiscActivityDraftAttachmentsSelected,
} from "../redux/actions/caseActions";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants";
import { miscActivityGroups } from "../constants/miscActivityGroupConstants";
import AttachFileModal from "./AttachFileModal";
import storageService from "../services/storageService";
import { MiscActivityCategory } from "../constants/miscActivityCategory";

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: "100%",
    },
    chip: {
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    input: {
        display: "none",
    },
    formItem: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    fileUploadButton: {
        width: "fit-content",
    },
}));

const MiscActivityEditor = ({ unallocatedSeconds, caseId, group }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { miscActivityTypes } = useSelector((state) => state.configReducer);
    const { currentCaseId, cases } = useSelector((state) => state.caseReducer);
    const [attachFileModalOpen, setAttachFileModalOpen] = useState(false);

    caseId = caseId === undefined ? currentCaseId : caseId;

    const c = cases[caseId];
    const { attachments, draftAttachmentsSelected } = c?.miscActivity;

    const activity =
        group === miscActivityGroups.CURRENT
            ? c?.miscActivity?.current
            : c?.miscActivity?.retrospective;

    const handleDeleteNewFile = async (file, isDraft = false) => {
        if (isDraft) {
            dispatch(
                setMiscActivityDraftAttachmentsSelected(
                    caseId,
                    draftAttachmentsSelected?.filter(
                        (attachment) => attachment.id !== file.id
                    )
                )
            );
            return;
        }
        if (!file.azureFileId) {
            try {
                await storageService.deleteBlob(file.id);
            }
            catch (e) {
                console.error(e);
            }
        }
        dispatch(
            setMiscActivityAttachmentsAction(
                caseId,
                attachments.filter(
                    (attachment) => attachment.id !== file.id
                )
            )
        )
    }

    const handleDeleteExistingFile = (file) => {
        dispatch(
            setMiscActivityFilesToDelete(
                [...activity.filesToDelete, file.reference],
                caseId
            )
        );
        dispatch(
            setExistingMiscActivityFiles(
                activity.existingFiles.filter(
                    (f) => f.reference !== file.reference
                ),
                caseId,
                group
            )
        );
    };

    const handleCloseAttachFileModal = () => {
        setAttachFileModalOpen(false);
    };

    useEffect(() => {
        if (unallocatedSeconds && group === miscActivityGroups.RETROSPECTIVE) {
            dispatch(
                setMiscActivityHours(
                    Math.floor(unallocatedSeconds / (60 * 60)),
                    caseId
                )
            );
            dispatch(
                setMiscActivityMinutes(
                    Math.ceil((unallocatedSeconds / 60) % 60),
                    caseId
                )
            );
        }
    }, [unallocatedSeconds, caseId, dispatch, group]);

    if (!activity) return null;

    return (
        <>
            <TextField
                className={classes.formItem}
                fullWidth
                variant="outlined"
                value={activity.miscActivityTypeId}
                onChange={e => dispatch(setMiscActivityTypeId(e.target.value, caseId, group))}
                select
                label="Type"
            >
                {Object.values(miscActivityTypes)
                    .filter((at) => at.category !== MiscActivityCategory.CustomerNote && at.category !== MiscActivityCategory.SeekAdvice)
                    .sort((a,b) => a.description < b.description ? -1 : 1)
                    .map(at =>
                        <MenuItem
                            key={at.miscActivityTypeId}
                            value={at.miscActivityTypeId}
                        >
                            {at.description}
                        </MenuItem>
                    )}
            </TextField>
            {group === miscActivityGroups.RETROSPECTIVE && (
                <>
                    <KeyboardDatePicker
                        className={`${classes.datePicker} ${classes.formItem}`}
                        name="date"
                        format="dd/MM/yyyy"
                        label="Date"
                        value={activity.date}
                        onChange={date => dispatch(setMiscActivityDate(date, caseId))}
                        inputVariant="outlined"
                    />
                    <KeyboardTimePicker
                        className={`${classes.datePicker} ${classes.formItem}`}
                        id="time-picker"
                        label="Start Time"
                        value={activity.startTime}
                        onChange={date => dispatch(setMiscActivityStartTime(date, caseId))}
                        KeyboardButtonProps={{ "aria-label": "change time" }}
                        ampm={false}
                        inputVariant="outlined"
                    />
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formItem}
                                fullWidth
                                type="number"
                                variant="outlined"
                                value={activity.hours}
                                onChange={(e) =>
                                    dispatch(
                                        setMiscActivityHours(
                                            e.target.value,
                                            caseId
                                        )
                                    )
                                }
                                inputProps={{ min: 0 }}
                                label="Hours"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formItem}
                                fullWidth
                                type="number"
                                variant="outlined"
                                value={activity.minutes}
                                onChange={(e) =>
                                    dispatch(
                                        setMiscActivityMinutes(
                                            e.target.value,
                                            caseId
                                        )
                                    )
                                }
                                inputProps={{ min: 0 }}
                                label="Minutes"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <TextField
                className={classes.formItem}
                fullWidth
                value={activity.description}
                onChange={(e) => dispatch(setMiscActivityDescription(e.target.value, caseId, group))}
                label="Description"
                multiline
                variant="outlined"
                inputProps={{ maxLength: MAX_ACTIVITY_DESCRIPTION }}
            />
            <TextField
                className={classes.formItem}
                fullWidth
                value={activity.notes}
                onChange={(e) => dispatch(setMiscActivityNotes(e.target.value, caseId, group))}
                label="Notes"
                multiline
                minRows={4}
                variant="outlined"
            />
            <Box flexGrow={1} mt={3} display="flex" flexWrap="wrap">
                {activity.existingFiles?.length > 0 && (
                    <>
                        {activity.existingFiles.map((file) => (
                            <Box mb={1} mr={1} key={file.reference}>
                                <Chip
                                    className={classes.chip}
                                    label={
                                        <Typography variant="body2">
                                            {file.filename}
                                        </Typography>
                                    }
                                    onDelete={() =>
                                        handleDeleteExistingFile(file)
                                    }
                                />
                            </Box>
                        ))}
                    </>
                )}
                {(attachments?.length > 0 ||
                    draftAttachmentsSelected?.length > 0) && (
                        <>
                            {attachments?.map((file, index) => (
                                <Box mb={1} mr={1} key={index}>
                                    <Chip
                                        className={classes.chip}
                                        label={
                                            <Typography variant="body2">
                                                {file.file.name}
                                            </Typography>
                                        }
                                        onDelete={() => handleDeleteNewFile(file)}
                                    />
                                </Box>
                            ))}
                            {draftAttachmentsSelected?.map((file) => (
                                <Box mb={1} mr={1} key={file.id}>
                                    <Chip
                                        className={classes.chip}
                                        label={
                                            <Typography variant="body2">
                                                {file.name}
                                            </Typography>
                                        }
                                        onDelete={() =>
                                            handleDeleteNewFile(file, true)
                                        }
                                    />
                                </Box>
                            ))}
                        </>
                    )}
            </Box>
            <Button
                color="primary"
                variant="contained"
                component="label"
                className={`${classes.formItem} ${classes.fileUploadButton}`}
                onClick={() => setAttachFileModalOpen(true)}
            >
                Attach Files
            </Button>
            <AttachFileModal
                open={attachFileModalOpen}
                closeAttachFileModal={handleCloseAttachFileModal}
                caseId={caseId}
                draftAttachmentsSelected={draftAttachmentsSelected}
                attachments={attachments}
                isMiscActivity
            />
        </>
    );
};

export default MiscActivityEditor;
