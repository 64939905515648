import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getCaseOutcomes = async (isEt, forDropdown, adviceTypeId) => {
    try {
        const outcomes = await axios.get(`${apiUrl}/caseoutcomes/${isEt}/${forDropdown}/${adviceTypeId}`);
        return outcomes.data;
    } catch (e) {
        throw e;
    }
};

const caseOutcomeService = {
    getCaseOutcomes,
};

export default caseOutcomeService;
