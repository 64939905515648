import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { format, isSameDay } from "date-fns";
import { connect, useDispatch } from "react-redux";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import { blue, green, grey } from "@material-ui/core/colors";
import {
    Person,
    Phone as OutgoingCallIcon,
    PhoneEnabled as IncomingCallIcon,
    Replay as UndoIcon,
    Search,
} from "@material-ui/icons";
import {
    setCallContactToIndex as setCallContactToIndexAction,
    setCallNoteCall as setCallNoteCallAction,
    setCallNoteContact as setCallNoteContactAction,
    setCallNoteDescription as setCallNoteDescriptionAction,
    setCallNoteDescriptionFocus as setCallNoteDescriptionFocusAction,
    setCallNoteOutbound as setCallNoteOutboundAction,
} from "../redux/actions/caseActions";
import { dial as dialAction } from "../redux/actions/flexActions";
import { setCallNoteInteracted } from "../redux/actions/caseTimerActions";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import { emptyCall, emptyCallNote } from "../constants/emptyActivities";
import { addCall, addCallAndNote, updateNote } from "../redux/actions/thunks";
import { setSnackAction } from "../redux/actions/snackActions";
import callNoteModes from "../constants/callNoteModes";
import contactService from "../services/contactService";
import callService from "../services/callService";
import caseService from "../services/caseService";
import AllocatedCallTimeDialog from "../components/AllocatedCallTimeDialog";
import useCaseExternalContacts from "../hooks/queries/useCaseExternalContacts";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants";
import CaseTimeEventGroupsDialog from "../components/dialogs/CaseTimeEventGroupsDialog";
import useCaseTimeEventGroupsForUserAndAccount from "../hooks/queries/useCaseTimeEventGroupsForUserAndAccount";
import anonymousNumber from "../constants/anonymousNumber";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";

const useStyles = makeStyles((theme) => ({
    description: {
        width: "100%",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    noteText: {
        width: "100%",
    },
    outgoingCallIcon: {
        color: blue[600],
    },
    incomingCallIcon: {
        color: green[500],
    },
    callMenu: {
        borderRadius: "0px",
        "& ul": {
            padding: "0px",
        },
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    textTitle: {
        marginTop: theme.spacing(2),
    },
    activeCall: {
        backgroundColor: grey[100],
    },
}));

const Call = ({ call, activeCall = false }) => {
    const classes = useStyles();

    let startDate = new Date(call.start);

    return (
        <Box
            display="flex"
            my={activeCall ? 3 : 0}
            p={activeCall ? 3 : 0}
            width="100%"
            className={activeCall ? classes.activeCall : ""}
        >
            <Box display="flex" flexGrow={1} width="100%">
                <Box display="flex" flexGrow={1} justifyContent="flex-start">
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        mr={3}
                    >
                        {call.direction === 0 ? (
                            <IncomingCallIcon
                                fontSize="large"
                                className={classes.incomingCallIcon}
                            />
                        ) : (
                            <OutgoingCallIcon
                                fontSize="large"
                                className={classes.outgoingCallIcon}
                            />
                        )}
                        <Typography variant="subtitle2">
                            {call.direction === 0 ? "Incoming" : "Outgoing"}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        mr={!activeCall ? 6 : 0}
                    >
                        {
                            call.contactName && 
                            <Typography variant="body1">{call.contactName || "Unknown Contact"} - {call.accountName || "Unknown Account"}</Typography>
                        }
                        <Typography
                            variant={call.contactName ? "body2" : "body1"}
                        >
                            {
                                call.phone === anonymousNumber
                                    ? "Anonymous"
                                    : call.phone
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">{!isSameDay(startDate, new Date()) && format(startDate, "dd/MM")} {format(startDate, "HH:mm")}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const activityTypes = {
    CALL: 0,
    CALL_AND_NOTE: 1,
};

const CallCard = ({
    userId,
    close,
    setCallNoteCall,
    setCallNoteMode,
    setCallNoteContact,
    setCallNoteText,
    setCallNoteCaseActivityId,
    setCallNoteIssueFocus,
    setCallNoteAdviceFocus,
    setCallNoteActionFocus,
    dial,
    setCallNoteOutbound,
    setCallContactToIndex,
    caseTypes,
    caseState,
    currentCallId,
    setSnack,
}) => {
    const classes = useStyles();    

    const { currentCaseId: caseId, cases } = caseState;

    const dispatch = useDispatch();

    const c = cases[caseId];
    const accountId = c.account.accountId;
    const activeCall = c.callNote.call || emptyCall;
    const mode = c.callNote.mode;
    const contact = c.callNote.contact;
    const note = c.callNote.text;
    const caseActivityId = c.callNote.caseActivityId;
    const outbound = c.callNote.outbound;
    const caseActivityIds = c.caseActivityIds;
    const caseIsChargeable = c.caseSummary.isChargeable;
    const saving = c.callNote.saving;
    const description = c.callNote.description;
    const caseTypeId = c.caseSummary.caseTypeId;
    const adviceTypeId = useMemo(() => caseTypes[caseTypeId]?.adviceTypeId, [caseTypeId, caseTypes]);
    const adviceTypes = useAdviceTypes();
    const recordingDisabled = useMemo(
        () => adviceTypes.data?.find((at => at.id === adviceTypeId))?.recordingDisabled ?? false,
        [adviceTypeId, adviceTypes.data]
    );
    const [calls, setCalls] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [matchingContacts, setMatchingContacts] = useState([]);
    const [contactQuery, setContactQuery] = useState("");
    const [contactsLoading, setContactsLoading] = useState(false);
    const [anchorElContact, setAnchorElContact] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const [allocatedCallTime, setAllocatedCallTime] = useState(null);
    const [activityType, setActivityType] = useState(null);
    const [externalContactSearchText, setExternalContactSearchText] = useState(null);

    const {
        data: externalContacts,
        isError: isExternalContactsError,
        isLoading: isExternalContactsLoading,
        error: externalContactsError,
    } = useCaseExternalContacts({ caseId, externalContactSearchText });

    const callNoteRef = useRef(null);

    const { hasRole } = useAuth();
    const hasFlexRole = hasRole(userRoles.LEGAL_ADVISOR);

    const [heldTimeOpen, setHeldTimeOpen] = useState(false);
    const { data: caseTimeEventGroups } = useCaseTimeEventGroupsForUserAndAccount({ userId, accountId });
    const [transferAllocatedCallTime, setTransferAllocatedCallTime] = useState(false);

    const getCalls = useCallback(async () => {
        try {
            const response = await callService.getCalls({ userId, accountId });
            setCalls(response.data);
        } catch (e) {
            console.error(e);
        }
    }, [userId, accountId]);

    const handleChangeCall = (call) => {
        setCallNoteCall(calls.filter((c) => c.callId === call.callId)[0], caseId);

        if (call.direction === 0)
            setCallNoteOutbound(false, caseId);
        
        setAnchorEl(null);
    };

    const handleContactSearch = async (node) => {
        try {
            setContactsLoading(true);
            setAnchorElContact(node);
            contactQuery && setExternalContactSearchText(contactQuery);
            const response = await contactService.getContacts({
                fullName: contactQuery,
                accountId,
            });

            setContacts(response.data.contacts);
        } catch (e) {
            console.error(e);
        } finally {
            setContactsLoading(false);
        }
    };

    const handleEnterKeyPress = (e, node) => {
        if (e.key === "Enter") {
            handleContactSearch(node);
        }
    };

    const handleClickContact = (c) => {
        setCallNoteContact(c, caseId);
        setAnchorElContact(null, caseId);
    };

    const getAllocatedCallTime = async () => {
        try {
            const response = await caseService.getAllocatedCallTime({
                caseId,
                callId: activeCall.callId,
            });
            if (!!response.data?.length) setAllocatedCallTime(response.data);
            return response.data;
        } catch (e) {
            setSnack("There was an error getting allocated call time", "error");
            throw e;
        }
    };

    const handleClickSaveCallAndNote = async () => {
        if (!description.length > 0) {
            setSnack("Call note must have a description", "warning")
            return;
        }

        setActivityType(activityTypes.CALL_AND_NOTE);

        try {
            const allocatedCallTime = await getAllocatedCallTime();
            if (!!allocatedCallTime?.length) return;
        } catch (e) {
            return;
        }

        handleSaveCallAndNote();
    };

    const handleClickSaveCall = async () => {
        setOpen(false);

        setActivityType(activityTypes.CALL);

        try {
            const allocatedCallTime = await getAllocatedCallTime();
            if (!!allocatedCallTime?.length) return;
        } catch (e) {
            return;
        }

        handleSaveCall();
    };

    const handleSaveCallAndNote = (
        shouldTransferAllocatedCallTime = false,
        caseTimeEventGroupIds = []
    ) => {
        if (caseTimeEventGroups?.length && !heldTimeOpen && !caseActivityId) {
            setTransferAllocatedCallTime(shouldTransferAllocatedCallTime);
            setHeldTimeOpen(true);
            return;
        }
        setHeldTimeOpen(false);

        dispatch(
            addCallAndNote({
                activeCall,
                contactId: contact.contactId,
                contactName: contact.name,
                externalContactId: contact.externalContactId,
                accountId,
                note,
                caseIsChargeable,
                isAdvisor: hasFlexRole,
                description,
                transferAllocatedCallTime: shouldTransferAllocatedCallTime,
                caseTimeEventGroupIds,
            })
        );
        setTransferAllocatedCallTime(false);
    };

    const handleSaveCall = (shouldTransferAllocatedCallTime = false) => {
        dispatch(
            addCall({
                activeCall,
                contactId: contact.contactId,
                contactName: contact.name,
                externalContactId: contact.externalContactId,
                accountId,
                caseIsChargeable,
                isAdvisor: hasFlexRole,
                transferAllocatedCallTime: shouldTransferAllocatedCallTime,
            })
        );
    };

    const handleUpdateCallNote = async () => {
        if (!description.length > 0) {
            setSnack("Call note must have a description", "warning");
            return;
        }

        dispatch(
            updateNote({
                caseActivityId,
                note,
                description,
            })
        );
    };

    const handleReset = () => {
        setCallNoteText(emptyCallNote, caseId);
        setCallNoteCall(emptyCall, caseId);
        close();
        setCallNoteCaseActivityId(null, caseId);
        setCallNoteMode(callNoteModes.CALL, caseId);
    };

    const getPhoneString = () => {
        if (!matchingContacts.length)
            return contact.phone || contact.mobile || "No number available";
        else {
            let phone = contact.phone;
            if (phone) {
                phone = phone.replace("+44", "").replace(" ", "");
                if (phone.startsWith("0")) phone = phone.substring(1);
                if (activeCall.phone.includes(phone)) return contact.phone;
            }

            let mobile = contact.mobile;
            if (mobile) {
                mobile = mobile.replace("+44", "").replace(" ", "");
                if (mobile.startsWith("0")) mobile = mobile.substring(1);
                if (activeCall.phone.includes(mobile)) return contact.mobile;
            }

            return contact.phone || contact.mobile || "No number available";
        }
    };

    const nextTo = () =>
        setCallContactToIndex(
            caseId,
            (contact.toIndex + 1) % contact.numbers.length
        );

    const handleClickLogCall = () => {
        dispatch(setCallNoteInteracted());
        setCallNoteMode(callNoteModes.NOTE, caseId);
    };

    const handleClickSaveWithoutNote = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAllocatedCallTime(null);
        setActivityType(null);
    };

    const handleConfirm = (shouldTransferAllocatedCallTime) => {
        switch (activityType) {
            case activityTypes.CALL:
                handleSaveCall(shouldTransferAllocatedCallTime);
                break;

            case activityTypes.CALL_AND_NOTE:
                handleSaveCallAndNote(shouldTransferAllocatedCallTime);
                break;

            default:
                return;
        }

        handleClose();
    };

    const handleSaveFromHeldTimeDialog = (_e, caseTimeEventGroupIds = []) => {
        handleSaveCallAndNote(transferAllocatedCallTime, caseTimeEventGroupIds);
    };

    const handleCloseHeldTimeDialog = () => {
        setHeldTimeOpen(false);
        setTransferAllocatedCallTime(false);
    };

    useEffect(() => {
        if (currentCallId && calls.length && !activeCall.callId) {
            setCallNoteOutbound(false, caseId);
            setCallNoteCall(calls.find((c) => c.callId === currentCallId), caseId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCallId, calls, caseId, activeCall]);

    useEffect(() => {
        getCalls();
    }, [getCalls]);

    useEffect(() => {
        const getMatchingContacts = async () => {
            try {
                const response = await contactService.getContacts({
                    accountId,
                    phone: activeCall.phone,
                    pageNum: 1,
                    pageSize: 100,
                });

                setMatchingContacts(...response.data.contacts, externalContacts);
            } catch (e) {
                console.error(e);
            }
        };
        if (activeCall.callId) {
            getMatchingContacts();
        }
    }, [activeCall.callId, accountId, activeCall.phone]);

    useEffect(() => {
        if (mode !== callNoteModes.NOTE) {
            if (matchingContacts.length === 1)
                setCallNoteContact(matchingContacts[0], caseId);
            else if (matchingContacts.length > 1 && activeCall.contactId)
                setCallNoteContact(matchingContacts.find((c) => c.contactId === activeCall.contactId), caseId);
            else
                setCallNoteContact(null, caseId);
        }
    }, [matchingContacts, mode]);

    useEffect(() => {
        if (mode === callNoteModes.NOTE)
            callNoteRef.current.focus();
    }, [mode]);

    return (
        <React.Fragment>
            <Card className={classes.card}>
                <CardContent>
                    {mode === callNoteModes.CALL && (
                        <React.Fragment>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Call
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={handleReset}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {activeCall.callId ? (
                                <Box display="flex" flexDirection="column">
                                    <Call call={activeCall} activeCall />
                                    <Box mt={3}>
                                        <Typography variant="caption">
                                            Not the right call?
                                        </Typography>
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) =>
                                                setAnchorEl(
                                                    e.currentTarget.parentNode
                                                )
                                            }
                                        >
                                            Change Call
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box>
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            Looks like you're not on a call
                                        </Typography>
                                    </Box>
                                    <Box
                                        my={3}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) =>
                                                setAnchorEl(
                                                    e.currentTarget.parentNode
                                                )
                                            }
                                        >
                                            Choose Call
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) =>setCallNoteOutbound(true, caseId)}
                                        >
                                            Outbound Call
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            <Typography variant="h6">Contact</Typography>
                            {activeCall.callId &&
                                !contact &&
                                matchingContacts.length > 1 && (
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            Looks like multiple contacts matched
                                            this number. Please search for the
                                            right contact below
                                        </Typography>
                                    </Box>
                                )}
                            {activeCall.callId &&
                                !contact &&
                                matchingContacts.length === 0 && (
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            No contacts match this number.
                                            Please add a new contact, or search
                                            for an existing one
                                        </Typography>
                                    </Box>
                                )}
                            {!activeCall.callId && !outbound && (
                                <Box my={3}>
                                    <Typography variant="caption">
                                        Please select a call before assigning a contact
                                    </Typography>
                                </Box>
                            )}
                            {contact && (
                                <Box display="flex" justifyContent="center">
                                    <Chip
                                        icon={<Person />}
                                        label={
                                            contact.name +
                                            " - " +
                                            (outbound
                                                ? contact.numbers[
                                                      contact.toIndex
                                                  ] || "No number available"
                                                : getPhoneString())
                                        }
                                        onClick={
                                            outbound &&
                                            contact.numbers.length > 1
                                                ? nextTo
                                                : null
                                        }
                                    />
                                    {outbound && (
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={() =>
                                                dial(
                                                    contact.numbers[contact.toIndex],
                                                    contact.contactId,
                                                    accountId,
                                                    undefined,
                                                    recordingDisabled,
                                                )
                                            }
                                            disabled={
                                                (!contact.phone &&
                                                    !contact.mobile) ||
                                                !hasFlexRole
                                            }
                                        >
                                            <OutgoingCallIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                            {((activeCall.callId && !contact) ||
                                (outbound && !contact)) && (
                                <Box display="flex" justifyContent="center">
                                    <FormControl>
                                        <InputLabel htmlFor="call-contact-search">
                                            Contact
                                        </InputLabel>
                                        <Input
                                            id="call-contact-search"
                                            type="text"
                                            value={contactQuery}
                                            onChange={(e) =>
                                                setContactQuery(e.target.value)
                                            }
                                            onKeyPress={(e) =>
                                                handleEnterKeyPress(
                                                    e,
                                                    e.currentTarget
                                                )
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={classes.iconButton}
                                                        onClick={(e) => handleContactSearch(e.currentTarget.parentNode.parentNode)}
                                                    >
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            )}
                            {contact && !activeCall.contactId && (
                                <Box>
                                    <Box my={3}>
                                        <Typography variant="caption">
                                            Not the right contact?
                                        </Typography>
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) =>
                                                setCallNoteContact(null, caseId)
                                            }
                                        >
                                            Change Contact
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {activeCall.callId && contact && (
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        onClick={handleClickLogCall}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Log Call
                                    </Button>
                                </Box>
                            )}
                            <Menu
                                className={classes.callMenu}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                            >
                                {!calls.filter(
                                    (c) =>
                                        c.callId !== activeCall.callId &&
                                        !c.caseActivityIds.some((id) =>
                                            caseActivityIds.includes(id)
                                        )
                                ).length && <MenuItem>No calls found</MenuItem>}
                                {calls
                                    .filter(
                                        (c) =>
                                            c.callId !== activeCall.callId &&
                                            !c.caseActivityIds.some((id) =>
                                                caseActivityIds.includes(id)
                                            )
                                    )
                                    .map((c) => (
                                        <MenuItem
                                            key={c.callId}
                                            onClick={() => handleChangeCall(c)}
                                        >
                                            <Call call={c} />
                                        </MenuItem>
                                    ))}
                            </Menu>
                            <Menu
                                anchorEl={anchorElContact}
                                keepMounted
                                open={Boolean(anchorElContact)}
                                onClose={() => setAnchorElContact(null)}
                            >
                                {!contactsLoading && contacts.length
                                    ? contacts
                                          .map((c) => (
                                              <MenuItem
                                                  key={c.contactId}
                                                  onClick={() =>
                                                      handleClickContact(c)
                                                  }
                                              >
                                                  {c.name}
                                              </MenuItem>
                                          ))
                                    : null}
                                {isExternalContactsError ? (
                                    <Typography>
                                        {externalContactsError?.message ||
                                            "Failed to load External Contacts"}
                                    </Typography>
                                ) : !isExternalContactsLoading &&
                                  externalContacts.length ? (
                                    externalContacts
                                        .map((c) => (
                                            <MenuItem
                                                key={c.externalContactId}
                                                onClick={() =>
                                                    handleClickContact(c)
                                                }
                                            >
                                                {"[Ext] " + c.name}
                                            </MenuItem>
                                        ))
                                ) : null}
                                {!isExternalContactsLoading &&
                                    !contactsLoading &&
                                    !externalContacts.length &&
                                    !contacts.length && (
                                        <MenuItem>No contacts found</MenuItem>
                                    )}

                                {(isExternalContactsLoading ||
                                    contactsLoading) && (
                                    <MenuItem>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <CircularProgress />
                                        </Box>
                                    </MenuItem>
                                )}
                            </Menu>
                        </React.Fragment>
                    )}

                    {mode === callNoteModes.NOTE && (
                        <React.Fragment>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Call Note
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={handleReset}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Issue
                            </Typography>
                            <TextField
                                inputRef={callNoteRef}
                                className={classes.noteText}
                                value={note.issue}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, issue: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteIssueFocus(true)}
                                onBlur={() => setCallNoteIssueFocus(false)}
                            />
                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Advice
                            </Typography>
                            <TextField
                                className={classes.noteText}
                                value={note.advice}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, advice: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteAdviceFocus(true)}
                                onBlur={() => setCallNoteAdviceFocus(false)}
                            />
                            <Typography
                                variant="subtitle2"
                                className={classes.textTitle}
                            >
                                Action
                            </Typography>
                            <TextField
                                className={classes.noteText}
                                value={note.action}
                                onChange={(e) =>
                                    setCallNoteText(
                                        { ...note, action: e.target.value },
                                        caseId
                                    )
                                }
                                multiline
                                onFocus={() => setCallNoteActionFocus(true)}
                                onBlur={() => setCallNoteActionFocus(false)}
                            />
                            <TextField
                                className={classes.description}
                                value={description}
                                onChange={(e) =>dispatch(setCallNoteDescriptionAction(e.target.value))}
                                onFocus={() => dispatch(setCallNoteDescriptionFocusAction(true))}
                                onBlur={() => dispatch(setCallNoteDescriptionFocusAction(false))}
                                variant="outlined"
                                label="Description"
                                inputProps={{
                                    maxLength: MAX_ACTIVITY_DESCRIPTION,
                                }}
                            />
                            <Box
                                my={3}
                                display="flex"
                                justifyContent="space-between"
                            >
                                {saving ? (
                                    <CircularProgress />
                                ) : caseActivityId ? (
                                    <Button
                                        onClick={handleUpdateCallNote}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Update Call Note
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            onClick={handleClickSaveWithoutNote}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save Call without Note
                                        </Button>
                                        <Button
                                            onClick={handleClickSaveCallAndNote}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Create Call Note
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Box>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Save call without note?</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to save this call without a note?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSaveCall} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <AllocatedCallTimeDialog
                allocatedCallTime={allocatedCallTime}
                caseId={caseId}
                handleClose={() => setAllocatedCallTime(null)}
                handleConfirm={handleConfirm}
            />
            <CaseTimeEventGroupsDialog
                open={heldTimeOpen}
                onClose={handleCloseHeldTimeDialog}
                onConfirm={handleSaveFromHeldTimeDialog}
                confirmLabel="Save"
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    currentCallId: state.flexReducer.currentCallId,
    caseTypes: state.configReducer.caseTypes,
});


const mapDispatchToProps = (dispatch) => ({
    setCallNoteContact: (contact, caseId) => dispatch(setCallNoteContactAction(contact, caseId)),
    dial: (number, contactId, accountId, outboundNumber, recordingDisabled) => dispatch(dialAction(number, contactId, accountId, outboundNumber, recordingDisabled)),
    setCallNoteOutbound: (outbound, caseId) => dispatch(setCallNoteOutboundAction(outbound, caseId)),
    setCallNoteCall: (call, caseId) => dispatch(setCallNoteCallAction(call, caseId)),
    setCallContactToIndex: (caseId, index) => dispatch(setCallContactToIndexAction(caseId, index)),
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallCard);
