import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ConfirmCompleteTaskDialog = ({ open, channelType, sid, setTaskInfo, emailAllocated, taskCompletionReasons, taskSid }) => {
    const classes = useStyles();

    const [reasonId, setReasonId] = useState("");

    const handleClose = () => {
        setTaskInfo(false, null, null, false, null);
    };

    const handleConfirm = () => {
        window.frames[0].postMessage({ event: "confirmCompleteTask", sid, reasonId, taskSid }, "*");
        handleClose();
    };

    let content = "Unknown channel type. Support needs adding for this.";

    if (channelType === "email" && emailAllocated) {
        content = "You have allocated this email to a case but not sent a reply. Are you sure you want to complete the task?";
    }

    if (channelType === "email" && !emailAllocated) {
        content = "You have not allocated this email task to a case. Are you want to complete the task?";
    }

    if ((channelType === "whatsapp" || channelType === "sms") && emailAllocated) {
        content = "You have allocated this chat to a case but not logged an activity. Are you sure you want to complete the task?";
    }

    if ((channelType === "whatsapp" || channelType === "sms") && !emailAllocated) {
        content = "You have not allocated this chat task to a case. Are you want to complete the task?";
    }

    if (channelType === "voice") {
        content = "You have not allocated this call to a call note. Are you sure you want to complete the task?";
    }

    if (channelType === "callback") {
        content = "You have not attempted a callback. Are you sure you want to complete the task?";
    }
    
    if (channelType === "seek-advice" && emailAllocated) {
        content = "You have not replied to this Seek Advice query. Are you sure you want to complete the task?";
    }

    if (channelType === "seek-advice" && !emailAllocated) {
        content = "You have not assigned this Seek Advice query to a case. Are you sure you want to complete the task?";
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Complete task?</DialogTitle>
                
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
                <Box display="flex" justifyContent="center">
                    <FormControl className={classes.formControl}>
                        <InputLabel>Reason</InputLabel>
                        <Select value={reasonId} onChange={e => setReasonId(e.target.value)}>
                            {taskCompletionReasons.map(r =>
                                <MenuItem key={r.taskCompletionReasonId} value={r.taskCompletionReasonId}>{r.description}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleConfirm} color="primary" disabled={!reasonId}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmCompleteTaskDialog;