import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import avatarStyles from "../theme/avatars";
import axios from "../plugins/axios";
import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseAdvisers } from "../redux/actions/caseActions";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";
import useAccountAdvisers from "../hooks/queries/useAccountAdvisers";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";

import {
    Badge,
    CardContent,
    Card as MuiCard,
    Button as MuiIconButton,
    Avatar,
    Typography,
    Tooltip,
    Box,
    CircularProgress,
    Menu,
    MenuItem,
    Button
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { grey } from "@material-ui/core/colors";

import {
    Add as AddIcon,
    Star as PrimaryIcon,
    Delete as DeleteIcon,
    MoreHoriz,
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const LawyerCard = ({ setCaseAdvisers, setSnackAction, userState, caseState, caseTypes }) => {

    const { currentCaseId: caseId, cases } = caseState;

    const c = cases[caseId];
    const caseAdvisers = c.advisers;

    const adviceTypeId = useMemo(() => caseTypes[c.caseSummary.caseTypeId]?.adviceTypeId, [c.caseSummary.caseTypeId, caseTypes]);
    const { data: adviceTypes } = useAdviceTypes();
    const adviceType = useMemo(() => adviceTypes?.find(at => at.id === adviceTypeId), [adviceTypeId, adviceTypes]);

    const [showOthers, setShowOthers] = useState(false);    

    const avatarClasses = avatarStyles();
    const [mode, setMode] = useState("view");

    const { data: accountAdvisers } = useAccountAdvisers(c.account.accountId);

    const [currentCaseAdvisers, setCurrentCaseAdvisers] = useState(null);

    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextAdviser, setContextAdviser] = useState(null);   

    const [anchorEl, setAnchorEl] = useState(null);

    const allAdvisers = useMemo(() => {

        let top = accountAdvisers || [];

        for (const adviser of top) {
            let userFromState = userState.users[adviser.userId];
            if (userFromState) {
                adviser.photo = userFromState.photo;
                adviser.adviceTypes = userFromState.adviceTypes;
            }
                
        }

        let existingIds = top.map(u => u.userId);

        let extraActiveAdvisers = Object.values(userState.users)
            .filter(u => u.isActive && !existingIds.includes(u.userId))
            .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0);            

        return [...top, ...extraActiveAdvisers];
    }, [accountAdvisers, userState.users]);

    const getCaseAdvisers = useCallback(() => {
        const adviserIds = caseAdvisers.map((adviser) => adviser.adviserId);

        setCurrentCaseAdvisers(
            allAdvisers
                .filter((p) => adviserIds.includes(p.userId))
                .map((t) => ({
                    ...t,
                    isPrimary: caseAdvisers.filter(a => a.adviserId === t.userId)[0].isPrimary,
                }))
        );
    }, [allAdvisers, caseAdvisers]);

    const unusedAdvisers = useMemo(() => {

        var advisers = allAdvisers.filter(ac => !caseAdvisers.map((c) => c.adviserId).includes(ac.userId));

        if (showOthers)
            advisers = advisers.filter(x => !x.adviceTypes.includes(adviceTypeId));
        else
            advisers = advisers.filter(x => x.adviceTypes.includes(adviceTypeId));
           
        return advisers;
    }, [adviceTypeId, allAdvisers, caseAdvisers, showOthers]);

    useEffect(() => {
        getCaseAdvisers();        
    }, [getCaseAdvisers]);    

    const ViewAdviser = (adviser) => (
        <Box
            key={adviser.userId}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            style={{ textAlign: "center" }}
        >
            <Box alignSelf="flex-end">
                <IconButton onClick={(ev) => handleOpenContextMenu(adviser, ev)}>
                    <MoreHoriz />
                </IconButton>
            </Box>
            <Box>
                <Tooltip title={adviser.isPrimary ? "Primary Adviser" : ""}>
                    {adviser.isPrimary ? (
                        <Badge
                            overlap="rectangular"
                            variant="dot"
                            color="primary"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <Avatar
                                alt={adviser.name}
                                src={adviser.photo}
                                className={avatarClasses.large}
                            />
                        </Badge>
                    ) : (
                        <Avatar
                            alt={adviser.name}
                            src={adviser.photo}
                            className={avatarClasses.large}
                        />
                    )}
                </Tooltip>
            </Box>
            <Box>
                <Typography variant="subtitle2">{adviser.name}</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">{adviser.position}</Typography>
            </Box>
        </Box>
    );

    const handleOpenContextMenu = (e, event) => {
        setContextAdviser(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextAdviser(null);
    };

    const handlePrimary = async (adviserId) => {
        setMode("saving");
        handleCloseContextMenu();
        let response = await axios.post(
            `${apiUrl}/setcaseadvisers/${caseId}`,
            currentCaseAdvisers.map((ca) => ({
                adviserId: ca.userId,
                isPrimary: ca.userId === adviserId,
            }))
        );

        if (response.status === 200) {
            setCaseAdvisers(
                caseId,
                currentCaseAdvisers.map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.userId === adviserId,
                }))
            );

            setSnackAction("Saved!", "success");           
        }

        setMode("view");
    };

    const handleAdd = async (adviserId) => {
        setMode("saving");
        let response = await axios.post(`${apiUrl}/setcaseadvisers/${caseId}?isSaasCase=${c.isSaasCase}`, [
            ...currentCaseAdvisers.map((ca) => ({
                adviserId: ca.userId,
                isPrimary: ca.isPrimary,
            })),
            ...allAdvisers
                .filter((ac) => ac.userId === adviserId)
                .map((ac) => ({
                    adviserId: ac.userId,
                    isPrimary: ac.isPrimary,
                })),
        ]);
        if (response.status === 200) {
            setCaseAdvisers(caseId, [
                ...currentCaseAdvisers.map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.isPrimary,
                })),
                ...allAdvisers
                    .filter((ac) => ac.userId === adviserId)
                    .map((ac) => ({
                        adviserId: ac.userId,
                        isPrimary: ac.isPrimary,
                    })),
            ]);

            setSnackAction("Saved!", "success");
            
        }

        setMode("view");
        setAnchorEl(null);
    };

    const handleDelete = async (adviserId) => {
        handleCloseContextMenu();
        setMode("saving");

        let response = await axios.post(
            `${apiUrl}/setcaseadvisers/${caseId}?isSaasCase=${c.isSaasCase}`,
            currentCaseAdvisers
                .filter((c) => c.userId !== adviserId)
                .map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.isPrimary,
                }))
        );
        if (response.status === 200) {
            setCaseAdvisers(
                caseId,
                currentCaseAdvisers
                    .filter((c) => c.userId !== adviserId)
                    .map((ca) => ({
                        adviserId: ca.userId,
                        isPrimary: ca.isPrimary,
                    }))
            );
            setSnackAction("Saved!", "success");            
        }
        setMode("view");
    };

    return (
        <React.Fragment>
            <Card mb={6}>
                <CardContent>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>Adviser</Typography>
                        </Box>
                        <Box>
                            {mode !== "saving" && (
                                <IconButton
                                    onClick={(e) => setAnchorEl(e.currentTarget.parentNode)}
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                            {mode === "saving" && (
                                <CircularProgress
                                    size={21}
                                    style={{ marginLeft: "9px" }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-around"
                        flexWrap="wrap"
                        m={-2}
                    >
                        {currentCaseAdvisers &&
                            currentCaseAdvisers.map((e) => ViewAdviser(e))}
                    </Box>
                </CardContent>
            </Card>
            <CustomMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {adviceType && (
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={() => setShowOthers(!showOthers)}
                    >
                        {showOthers ? `Show ${adviceType?.name} only` : "Show Others"}
                    </Button>        
                )}               
                {unusedAdvisers
                    .map((c) => (
                        <MenuItem onClick={() => handleAdd(c.userId)} key={c.userId}>
                            <Avatar
                                alt={c.name}
                                src={c.photo}
                                style={{ marginRight: "8px" }}
                            />
                            <Typography variant="body2">
                                {c.name} - {c.position}
                            </Typography>
                        </MenuItem>
                    ))}
            </CustomMenu>
            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={Boolean(contextAnchorEl)}
                onClose={handleCloseContextMenu}
            >
                <MenuItem
                    disabled={mode === "saving" || (contextAdviser && contextAdviser.isPrimary)}
                    onClick={() => handlePrimary(contextAdviser.userId)}
                >
                    <PrimaryIcon style={{ marginRight: "6px", color: grey[700] }} />
                    {" Make primary"}
                </MenuItem>
                <MenuItem
                    disabled={mode === "saving" || (contextAdviser && contextAdviser.isPrimary)}
                    onClick={() => handleDelete(contextAdviser.userId)}
                >
                    <DeleteIcon  style={{ marginRight: "6px", color: grey[700] }} />
                    {" Delete"}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    userState: state.userReducer,
    caseState: state.caseReducer,
    caseTypes: state.configReducer.caseTypes,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnackAction: (message, severity) => dispatch(setSnackAction(message, severity)),
        setCaseAdvisers: (caseId, advisers) => dispatch(setCaseAdvisers(caseId, advisers)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LawyerCard);
