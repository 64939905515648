import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Tabs,
    Tab,
    Box,
    CircularProgress,
} from "@material-ui/core";
import useMeetingTranscripts from "../../hooks/queries/useMeetingTranscripts";
import Markdown from "markdown-to-jsx";
import TabPanel from "../../components/TabPanel";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

const useStyles = makeStyles((theme) => ({
    perfScroll: {
        maxHeight: "calc(100vh - 200px)",
        marginTop: '10px'
    },
}));

const MeetingTranscriptDialog = ({
    caseActivityId,
    close
}) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState();

    const { data: transcripts, isLoading } = useMeetingTranscripts(caseActivityId);    

    useEffect(() => {
        if (transcripts?.length)
            setCurrentTab(`t-${transcripts[0].transcriptId}`)
    }, [transcripts]);
    
    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    return (
        <Dialog
            onClose={close}
            open={!!caseActivityId}
            fullWidth
            maxWidth="md"
        >
            <DialogContent>
                <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
                    {
                        transcripts.map(t => [
                            <Tab key={`t-${t.transcriptId}`} value={`t-${t.transcriptId}`} label="Transcript" />,
                            <Tab key={`s-${t.transcriptId}`} value={`s-${t.transcriptId}`} label="Summary" />
                        ])
                    }                             
                </Tabs>
                <PerfectScrollbar className={classes.perfScroll}>
                    {
                        transcripts.map(t => <TabPanel value={currentTab} key={t.transcriptId} index={`t-${t.transcriptId}`} label="Transcript" style={{ whiteSpace: 'pre' }} >{t.content}</TabPanel>)
                    }
                    {
                        transcripts.map(t => <TabPanel value={currentTab} key={t.transcriptId} index={`s-${t.transcriptId}`} label="Summary" ><Markdown>{t.summary}</Markdown></TabPanel>)
                    }
                </PerfectScrollbar>                
            </DialogContent>
        </Dialog>
    );
};

export default MeetingTranscriptDialog;
