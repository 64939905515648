import { useQuery } from "react-query";
import queryKeys from "../constants/queryKeys";
import OpenAI from "openai";

const useAIAssistants = () =>
    useQuery([queryKeys.aiAssistants], async () => {

        const openai = new OpenAI({
            apiKey: 'sk-svcacct-vdlHz4_265OSRGmUTVRKj0ICcTpeiktLBISqyzyWSLNDrpgIN_klgC4NcXp47VYT3BlbkFJPChFwrAoKJpA9-9p-kG2gOuonm-37u7JgyoS2cAO3N_5_YZfrqVaqI2SPLUbcOQA',
            dangerouslyAllowBrowser: true
        });

        const assistants = await openai.beta.assistants.list({
            limit: 100,
        });

        console.log({ assistants });

        return assistants.data.filter(a => a.name.startsWith('Copilot'));

    }, {
        staleTime: Infinity,
    });

export default useAIAssistants;
