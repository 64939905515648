import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const addNewEmployee = async ({ caseId, accountId, employee, isSaasCase }) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addemployees/${accountId}/${caseId}?isSaasCase=${isSaasCase}`,
            [employee]
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const editCaseEmployees = async ({ employees, isSaasCase }) =>
    await axios.post(`${apiUrl}/editemployees?isSaasCase=${isSaasCase}`, employees);

const getYouManageEmployees = async ({ accountId, query }) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getyoumanageemployees/${accountId}?q=${query}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(
            error?.message || "Could not fetch YouManage employees"
        );
    }
};

const addYouManageEmployeeToCase = async ({ youManageEmployeeId, caseId }) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addyoumanageemployeetocase/${youManageEmployeeId}/${caseId}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(
            error?.message || "Could not add YouManage employee to Case"
        );
    }
};

const getAccountEmployees = async ({
    accountId,
    excludedEmployeeIds,
    limit,
    query
}) => {
    const paramExcludedEmployeeId = excludedEmployeeIds
        .map((id) => `excludedEmployeeIds=${id}&`)
        .join("");
    const paramQuery = query ? `&query=${query}` : '';
    const paramLimit = limit ? `&limit=${limit}` : '';
    try {
        const response = await axios.get(
            `${apiUrl}/getaccountemployees/${accountId}?${paramExcludedEmployeeId}${paramLimit}${paramQuery}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(
            error?.message || "Could not get Employees for Account"
        );
    }
};

const getEmployeeLinkedCases = async (employeeId) => {
    try {
        const response = await axios.get(
            `${apiUrl}/employees/${employeeId}/cases`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get linked cases for employee."
        )
    }
}

const addExistingEmployee = async ({ caseId, employeeId, isSaasCase }) =>
    await axios.post(`${apiUrl}/addexistingemployees/${caseId}?isSaasCase=${isSaasCase}`, [employeeId]);

const deleteEmployee = async ({ caseId, employeeId, isSaasCase }) =>
    await axios.delete(
        `${apiUrl}/deletecaseemployees/${caseId}?employeeIds=${employeeId}&isSaasCase=${isSaasCase}`
    );

const caseEmployeeService = {
    addNewEmployee,
    editCaseEmployees,
    getYouManageEmployees,
    addYouManageEmployeeToCase,
    getAccountEmployees,
    addExistingEmployee,
    deleteEmployee,
    getEmployeeLinkedCases,
};

export default caseEmployeeService;
