import { useDispatch, useSelector } from "react-redux";
import useETCaseDetails from "../hooks/queries/useETCaseDetails";
import React from 'react'
import { makeStyles } from "@material-ui/core";
import { ETClaimsDetailsCard, InsuranceDetailsCard, PercentageOfSuccessClaimTypeCard, ETOutcomeCard, Milestones, ETCostsCard, ETAwardsSettlementsCard } from "../cards/eTClaimDetails";
import useUpdateETCase from "../hooks/mutations/useUpdateETCase";
import { setSnackAction } from "../redux/actions/snackActions";

const useStyles = makeStyles((theme) => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2)
    }
}))

export function ETClaimDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const updateETCase = useUpdateETCase();
    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const adviceTypeId = cases[caseId].caseSummary.adviceTypeId;
    const { data, isLoading, isError, error } = useETCaseDetails(caseId);

    const handleSave = (changes) => {
        const withUpdates = !!data
            ? { ...data, ...changes, caseId }
            : { ...changes, caseId };

        updateETCase.mutate(
            { eTCase: withUpdates },
            {
                onError: (e) =>
                    dispatch(
                        setSnackAction(
                            e?.message ||
                                "There was an error updating ET case details",
                            "error"
                        )
                    ),
                onSuccess: () => {
                    dispatch(
                        setSnackAction("ET case details updated", "success")
                    );
                },
            }
        );
    };
    
    return (
        <div className={classes.layout}>
            <ETClaimsDetailsCard 
                onSave={handleSave}
                data={data} 
                isLoading={isLoading} 
                isError={isError} 
                error={error} 
            />
            <InsuranceDetailsCard 
                onSave={handleSave}
                data={data} 
                isLoading={isLoading} 
                isError={isError} 
                error={error} 
            />
            <ETCostsCard
                onSave={handleSave}
                data={data}
                isLoading={isLoading}
                isError={isError}
                error={error}
            />
            <ETAwardsSettlementsCard
                onSave={handleSave}
                data={data}
                isLoading={isLoading}
                isError={isError}
                error={error}
            />
            <PercentageOfSuccessClaimTypeCard
                caseId={caseId}
                etCaseId={data?.id}
            />
            <Milestones />
            <ETOutcomeCard
                adviceTypeId={adviceTypeId}
                isEtSaving={updateETCase.isLoading}
                caseId={caseId}
            />
        </div>
    )
}