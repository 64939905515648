import React, { useState, useEffect, useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Markdown from "markdown-to-jsx"
import PrecedentChooser from "./PrecedentChooser";
import {
    Button,
    makeStyles,
    Box,
    TextField,
    Grid,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Chip,
    Avatar,
    Typography,
    InputAdornment,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowUpIcon from "@material-ui/icons/ArrowUpward"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Loader from "../components/Loader";
import aiActionCreators from "../redux/actions/aiActionCreators";
import CaseHistoryItem from "../cards/CaseHistoryItem";
import { setFullScreenEmail } from "../redux/actions/userActions";
import useEmailByCaseActivityId from "../hooks/queries/useEmailByCaseActivityId";
import useAIAssistants from "../hooks/useAIAssistants";
import useCaseFiles from "../hooks/queries/useCaseFiles";
import documentService from "../services/documentService";
import PasswordProtectedFileDialog from "./dialogs/PasswordProtectedFileDialog";
import { openFileViewer } from "../redux/actions/userActions";
import OpenAI from "openai";
import avatarStyles from '../theme/avatars';
import aiImage from '../images/openai.svg';
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import useAIPrompts from "../hooks/queries/useAIPrompts";
import EmployeeCard from "../cards/EmployeeCard";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";

const openai = new OpenAI({
    apiKey: 'sk-svcacct-vdlHz4_265OSRGmUTVRKj0ICcTpeiktLBISqyzyWSLNDrpgIN_klgC4NcXp47VYT3BlbkFJPChFwrAoKJpA9-9p-kG2gOuonm-37u7JgyoS2cAO3N_5_YZfrqVaqI2SPLUbcOQA',
    dangerouslyAllowBrowser: true
});

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
    },
    attachmentChip: {
        maxWidth: '100%',
        paddingTop: "5px",
        paddingBottom: "5px",
        "& p": {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    perfScroll: {
        maxHeight: "calc(100vh - 505px)",
        marginTop: '10px'
    },
    aiIcon: {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: '#19c37d',
        borderRadius: '3px'
    },
    actionIcon: {
        color: 'grey',
        cursor: 'pointer',
        marginRight: '5px',
        "&:hover": {
            color: 'black'
        }
    }
}));

const PreBlock = ({ children, ...rest }) => {
    if ('type' in children && children['type'] === 'code') {
        return <div style={{ width: '100%' }}>
            {children}
        </div>
    }
    return <pre {...rest}>{children}</pre>;
};


const AITool = ({
    caseState,
    addAIMessage,
    runAIThread,
    internalUsers,
    setFullScreenEmail,
    setAssistantId,
    getCaseType,
    reset,
    openFileViewer,
    postFile,
    checkMessages,
    userProfile,
    setThumbsUp,
    clearAlert,
    setCurrentMessage
}) => {

    const classes = useStyles();

    const scrollRef = useRef();

    const avatarClasses = avatarStyles();

    const { currentCaseId: caseId, cases } = caseState;

    const c = cases[caseId];

    const { data: adviceTypes } = useAdviceTypes();

    const caseTypeId = useMemo(() => c.caseSummary.caseTypeId, [c.caseSummary.caseTypeId]);    

    const { processing, messages, assistantId, files, threadId, thumbsUpIds, thumbsDownIds, currentMessage } = c.aiState;    

    const { data: assistants } = useAIAssistants();

    const [passwordProtectedFile, setPasswordProtectedFile] = useState(null);

   const { data: caseFilesData } = useCaseFiles(caseId);

    const { data: prompts } = useAIPrompts();

    const generalPrompts = useMemo(() => {
        if (!prompts)
            return [];
        return prompts?.filter(p => !p.caseTypeId);
    }, [prompts]);

    const caseTypePrompts = useMemo(() => {
        if (!prompts)
            return [];
        return prompts.filter(p => p.caseTypeId === caseTypeId);
    }, [prompts, caseTypeId]);

    const recentEmails = useMemo(() => {
        if (!c.events)
            return [];
        return c.events.filter(e => e.itemType === "Email" && e.direction === 0).slice(0, 4);
    }, [c.events]);

    const [emailActivity, setEmailActivity] = useState();

    useEffect(() => {
        if (threadId)
            checkMessages(caseId);
    }, [caseId, checkMessages, threadId]);

    useEffect(() => {
        clearAlert(caseId);
    }, [caseId, clearAlert, messages]);

    useEmailByCaseActivityId({
        caseActivityId: emailActivity?.caseActivityId,
        onSuccess: (email) => {
            setFullScreenEmail(
                {
                    ...email.data,
                    ...emailActivity,
                    content: email.data.content,
                    person: email.data.person,
                }
            );
            setEmailActivity(null);
        },
    });

    const submit = async () => {

        addAIMessage(caseId, currentMessage);
        setCurrentMessage(caseId, '');
    };

    const caseType = useMemo(() => {
        if (!caseTypeId)
            return "??";

        let ct = getCaseType(caseTypeId);

        return ct.name;
    }, [caseTypeId, getCaseType]);

    const adviceType = useMemo(() => {
        if (!caseTypeId)
            return null;

        let ct = getCaseType(caseTypeId);

        let adviceType = adviceTypes?.find(at => at.id === ct.adviceTypeId);        

        return adviceType?.name;
    }, [adviceTypes, caseTypeId, getCaseType]);

    useEffect(() => {
        if (adviceType && assistants) {
            
            let assistant = assistants.find(a => a.name === `Copilot - ${adviceType}`);

            if (assistant)
                setAssistantId(caseId, assistant.id);
        }
    }, [assistants, caseId, setAssistantId, adviceType]);

    useEffect(() => {
        if (!processing) {            
            scrollRef.current.scrollIntoView(false);
        }

    }, [processing]);

    const submitAndRun = () => {

        if (currentMessage) {
            addAIMessage(caseId, currentMessage, true);
            setCurrentMessage(caseId, '');
        }
    }

    const mappedRoles = {
        user: 'Adviser',
        assistant: 'Copilot'
    };

    const sendFile = async (file) => {

        let filename = file.name;

        let attachment = {
            reference: file.azureFileReference,
            contentType: file.contentType,
            filename
        }

        const previewPath = await documentService.retrieveDocumentPreviewPath(attachment);

        if (previewPath === null) {
            setPasswordProtectedFile(attachment);
            return;
        }       

        postFile(caseId, previewPath, filename, file.contentType);
    };

    const sendPrecedent = async (p) => {        

        const previewPath = await documentService.retrieveDocumentPreviewPath({
            reference: p.azureFileReference,
            contentType: p.azureFileContentType,
            filename: p.name
        });

        postFile(caseId, previewPath, p.name, p.azureFileContentType);
    }



    //let extension = filename.split(".").pop().toUpperCase();

    const previewAIFile = async (file) => {

        var response = await openai.files.content(file.id);

        const data = await response.arrayBuffer();

        let filename = file.filename.split("/").pop();
        let extension = filename.split(".").pop().toUpperCase();

        let blob = new Blob([data], {
            type: 'application/octet-stream',
        });

        openFileViewer(
            {
                type: extension,
                name: filename,
            },
            caseId,
            null,
            blob
        );
    }

    const createDraftFile = async (file) => {

        var response = await openai.files.content(file.id);

        const data = await response.arrayBuffer();

        let filename = file.filename.split("/").pop();

        let blob = new Blob([data], {
            type: 'application/octet-stream',
        });

        const documentResponse = await documentService.openInSharepoint({
            blob,
            caseId,
            filename
        });
        window.open(documentResponse.data, "_blank");

    }

    const previewFile = async (file) => {

        let attachment = {
            reference: file.azureFileReference,
            contentType: file.contentType,
            filename: file.name
        }

        try {            

            const previewPath = await documentService.retrieveDocumentPreviewPath(attachment);

            if (previewPath === null) {
                setPasswordProtectedFile(attachment);
                return;
            }

            openFileViewer(
                {
                    type: file.extension,
                    path: previewPath,
                    name: attachment.filename,
                },
                caseId,
                attachment
            )
        } catch (e) {
            setPasswordProtectedFile(attachment);
            console.error(e);
        }
    };

    const confirmReset = () => {
        if (window.confirm("Are you sure that you want to clear chat?"))
            reset(caseId);
    }

    const submitEnabled = useMemo(() => {

        return currentMessage.trim().length > 0 && assistantId

    }, [assistantId, currentMessage]);

    const addPrompt = content => {
        setCurrentMessage(caseId, `${currentMessage}${content}\n`)
    }

    const copy = async (e) => {

        let element = e.currentTarget.parentNode.previousSibling;

        try {
            navigator.clipboard.write([new ClipboardItem({
                'text/plain': new Blob([element.innerText], { type: 'text/plain' }),
                'text/html': new Blob([element.innerHTML], { type: 'text/html' })
            })])
        }
        catch (err) {
            console.error(err);
        }
    };    

    const AIFileChip = ({ fileId }) => {

        let file = files.find(f => f.id === fileId) || { fileName: fileId };

       return (
            <Box m={1}>
                <Chip
                    disabled={file.purpose !== "assistants_output"}
                    className={classes.attachmentChip}
                    avatar={<Avatar><AttachFileIcon /></Avatar>}
                    label={<Typography variant="body2">{file.filename.split("/").pop()}</Typography>}
                    onClick={() => previewAIFile(file)}
                    onDelete={file.purpose === "assistants_output" ? (() => createDraftFile(file)) : null}
                    deleteIcon={<ArrowForwardIcon />}
                />
            </Box>
        );
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={4}>
                <Card className={classes.card}>
                    <CardContent>
                        {
                            recentEmails.map((e, index) => (
                                <CaseHistoryItem
                                    caseEvent={e}
                                    key={index}
                                    internalUsers={internalUsers}
                                    handlePreviewClick={() => setEmailActivity(e)}
                                />
                            ))}
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6">Case Files</Typography>
                        {
                            caseFilesData?.map(f =>
                                <Box m={1} key={f.azureFileReference}>
                                    <Chip
                                        title={f.name}
                                        disabled={processing}
                                        className={classes.attachmentChip}
                                        avatar={<Avatar><AttachFileIcon /></Avatar>}
                                        label={<Typography variant="body2">{f.name}</Typography>}
                                        onClick={() => previewFile(f)}
                                        onDelete={() => sendFile(f)}
                                        deleteIcon={<ArrowForwardIcon />}
                                    />
                                </Box>
                            )
                        }
                    </CardContent>
                </Card>
                <EmployeeCard/>
            </Grid>
            <Grid item xs={5}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box display="flex" alignItems="flex-end">
                            <Box flexGrow={1} style={{ marginRight: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Assistant</InputLabel>
                                    <Select
                                        value={assistantId || ""}
                                        fullWidth
                                        onChange={e => setAssistantId(caseId, e.target.value)}
                                    >
                                        <MenuItem value=""><em>None</em>
                                        </MenuItem>
                                        {
                                            assistants && assistants.sort((a, b) => a.name > b.name ? 1 : -1).map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <DeleteIcon
                                className={classes.aiIcon}
                                disabled={messages.length === 0}
                                variant="contained"
                                onClick={confirmReset}
                            />
                        </Box>
                        <PerfectScrollbar className={classes.perfScroll}>
                            {
                                messages
                                    .sort((m1, m2) => m1.created_at - m2.created_at)
                                    .map(m =>
                                        <Box key={m.id} display="flex" style={{ marginBottom: '10px' }}>
                                            <Box style={{ marginRight: '10px' }}>
                                                {
                                                    m.role === "assistant"
                                                        ? <Avatar alt="Copilot" src={aiImage} style={{ backgroundColor: '#19c37d', padding: '4px' }} className={avatarClasses.xs} />
                                                        : <Avatar alt={userProfile.name} src={userProfile.photo} className={avatarClasses.xs} />
                                                }
                                            </Box>
                                            <Box>
                                                <Box><strong>{mappedRoles[m.role] || m.role}</strong></Box>
                                                {
                                                    m.content.map((x, index) =>
                                                        <div key={index}>
                                                            {x.type === "image_file" ? (
                                                                <AIFileChip fileId={x.image_file.file_id}></AIFileChip>
                                                            ) : (
                                                                    <>
                                                                        <div>
                                                                            <Markdown options={{ overrides: { pre: PreBlock } }}>{x.text?.value || "**image**"}</Markdown>
                                                                        </div>
                                                                        {
                                                                            m.role === "assistant" && x.text.value &&
                                                                            <div>
                                                                                <AssignmentOutlinedIcon className={classes.actionIcon} fontSize="small" onClick={copy} />
                                                                                {
                                                                                    thumbsUpIds.includes(m.id)
                                                                                        ? <ThumbUpIcon className={classes.actionIcon} fontSize="small" style={{ color: 'green' }} />
                                                                                        : <ThumbUpOutlinedIcon className={classes.actionIcon} fontSize="small" style={{ color: 'green' }} onClick={() => setThumbsUp(caseId, m.id, true)} />
                                                                                }
                                                                                {
                                                                                    thumbsDownIds.includes(m.id)
                                                                                        ? <ThumbDownIcon className={classes.actionIcon} fontSize="small" style={{ color: 'red' }} />
                                                                                        : <ThumbDownOutlinedIcon className={classes.actionIcon} fontSize="small" style={{ color: 'red' }} onClick={() => setThumbsUp(caseId, m.id, false)} />
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </>
                                                            )}                                                            
                                                        </div>
                                                    )
                                                }
                                                {
                                                    m.attachments.map(attachment => <AIFileChip key={attachment.file_id} fileId={attachment.file_id}></AIFileChip>)
                                                }

                                            </Box>
                                            {
                                                m.role === "assistant" &&
                                                <Box>

                                                </Box>
                                            }
                                        </Box>
                                    )
                            }
                            <span ref={scrollRef} />
                        </PerfectScrollbar>
                        {
                            !processing &&
                            <>
                                <TextField
                                    minRows={4}
                                    style={{ marginBottom: '5px', marginTop: '5px' }}
                                    multiline
                                    fullWidth
                                    size="small"
                                    value={currentMessage}
                                    onChange={e => setCurrentMessage(caseId, e.target.value)}
                                    variant="outlined"
                                    placeholder="Enter your message"
                                    InputProps={
                                        submitEnabled
                                            ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ArrowUpIcon className={classes.aiIcon} onClick={submitAndRun} />
                                                    </InputAdornment>
                                                )
                                            }
                                            : null
                                    }
                                />
                                {
                                    false &&
                                    <Button
                                        style={{ marginBottom: '5px' }}
                                        disabled={currentMessage.trim().length === 0}
                                        fullWidth
                                        variant="contained"
                                        onClick={submit}>
                                        Submit
                                    </Button>
                                }
                                {
                                    false &&
                                    <Button
                                        style={{ marginBottom: '5px' }}
                                        disabled={messages.length === 0 || !assistantId}
                                        fullWidth
                                        variant="contained"
                                        onClick={() => runAIThread(caseId)}>
                                        Run
                                    </Button>
                                }
                            </>
                        }
                        {processing && <Loader style={{ padding: '10px' }} />}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card className={classes.card}>
                    <CardContent>
                        <PrecedentChooser isAITool onSelect={sendPrecedent} />
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6">Prompts</Typography>
                        {
                            generalPrompts.length > 0 &&
                            <>
                                <Typography>General</Typography>
                                <div style={{ marginLeft: '10px' }}>
                                    {
                                        generalPrompts.map(p =>
                                            <Box m={1} key={p.id}>
                                                <Chip
                                                    size="small"
                                                    className={classes.attachmentChip}
                                                    avatar={<Avatar><ArrowBackIcon /></Avatar>}
                                                    label={<Typography variant="body2">{p.title}</Typography>}
                                                    onClick={() => addPrompt(p.content)}
                                                />
                                            </Box>
                                        )
                                    }
                                </div>
                            </>
                        }
                        {
                            caseTypePrompts.length > 0 &&
                            <>
                                <Typography>{caseType}</Typography>
                                <div style={{ marginLeft: '10px' }}>
                                    {
                                        caseTypePrompts.map(p =>
                                            <Box m={1} key={p.id}>
                                                <Chip
                                                    size="small"
                                                    className={classes.attachmentChip}
                                                    avatar={<Avatar><ArrowBackIcon /></Avatar>}
                                                    label={<Typography variant="body2">{p.title}</Typography>}
                                                    onClick={() => addPrompt(p.content)}
                                                />
                                            </Box>
                                        )
                                    }
                                </div>
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>
            <PasswordProtectedFileDialog
                open={!!passwordProtectedFile}
                onClose={() => setPasswordProtectedFile(null)}
                fileName={passwordProtectedFile?.filename}
                caseString={" Case " + caseId}
                attachment={passwordProtectedFile}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    internalUsers: state.userReducer.users,
    userProfile: state.userReducer.userProfile,
    getCaseType: id => state.configReducer.caseTypes[id],
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(aiActionCreators, dispatch),
        setFullScreenEmail: (email) => dispatch(setFullScreenEmail(email)),
        openFileViewer: (obj, caseId, attachment, uploadFile) => dispatch(openFileViewer(obj, caseId, attachment, true, false, uploadFile))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AITool);
