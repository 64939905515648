import * as types from "../constants";

export function setIsSearching(isSearching) {
    return {
        type: types.SET_CASE_IS_SEARCHING,
        isSearching,
    };
}

export function setCriteriaModifiedDateTo(modifiedDateTo) {
    return {
        type: types.SET_CRITERIA_MODIFIED_DATE_TO,
        modifiedDateTo,
    };
}

export function setCriteriaModifiedDateFrom(modifiedDateFrom) {
    return {
        type: types.SET_CRITERIA_MODIFIED_DATE_FROM,
        modifiedDateFrom,
    };
}

export function setCriteriaCreatedDateTo(createdDateTo) {
    return {
        type: types.SET_CRITERIA_CREATED_DATE_TO,
        createdDateTo,
    };
}

export function setCriteriaCreatedDateFrom(createdDateFrom) {
    return {
        type: types.SET_CRITERIA_CREATED_DATE_FROM,
        createdDateFrom,
    };
}

export function setCriteriaEmployee(employee) {
    return {
        type: types.SET_CRITERIA_EMPLOYEE,
        employee,
    };
}

export function setCriteriaPartnershipId(partnershipId) {
    return {
        type: types.SET_CRITERIA_PARTNERSHIP_ID,
        partnershipId,
    };
}

export function setCriteriaAdviserId(adviserId) {
    return {
        type: types.SET_CRITERIA_ADVISER_ID,
        adviserId,
    };
}

export function setCriteriaCaseTypeId(caseTypeId) {
    return {
        type: types.SET_CRITERIA_CASE_TYPE_ID,
        caseTypeId,
    };
}

export function setCriteriaPartnerNumber(partnerNumber) {
    return {
        type: types.SET_CRITERIA_PARTNER_NUMBER,
        partnerNumber,
    };
}

export function setCriteriaAccountName(accountName) {
    return {
        type: types.SET_CRITERIA_ACCOUNT_NAME,
        accountName,
    };
}

export function setCriteriaPrimaryContact(primaryContact) {
    return {
        type: types.SET_CRITERIA_PRIMARY_CONTACT,
        primaryContact,
    };
}

export function setCriteriaCaseDescription(caseDescription) {
    return {
        type: types.SET_CRITERIA_CASE_DESCRIPTION,
        caseDescription,
    };
}

export function setDisplayCaseSearchInTab(display) {
    return {
        type: types.SET_DISPLAY_CASE_SEARCH_IN_TAB,
        display,
    };
}

export function setDisplayClosedCases(displayClosed) {
    return {
        type: types.SET_DISPLAY_CLOSED_CASES,
        displayClosed,
    };
}

export function setCurrentCaseAction(caseId) {
    return {
        type: types.SET_CURRENT_CASE,
        caseId: caseId,
    };
}

export function setCaseViewStateAction(caseId, viewState) {
    return {
        type: types.SET_CASE_VIEWSTATE,
        caseId: caseId,
        viewState: viewState,
    };
}

export function setActivityManage(caseId, activityType) {
    return {
        type: types.SET_CASE_VIEWSTATE_ACTIVITY_MANAGE,
        caseId: caseId,
        activityType: activityType,
    };
}

export function removeCaseTabAction(caseId) {
    return {
        type: types.REMOVE_CASE_TAB,
        caseId: caseId,
    };
}

export function setCaseClosed({ caseId, closureDate, caseOutcomeId }) {
    return {
        type: types.SET_CASE_CLOSED,
        payload: {
            caseId,
            closureDate,
            caseOutcomeId,
        },
    };
}

export function setCasePinned({ caseId, isPinned }) {
    return {
        type: types.SET_CASE_PINNED,
        payload: {
            caseId,
            isPinned,
        },
    };
}

export function setCaseTypeAndStage(caseId, caseTypeId, caseStageId, isET) {
    return {
        type: types.SET_CASE_TYPE_AND_STAGE,
        caseTypeId,
        currentStageId: caseStageId,
        caseId,
        isET,
    };
}

export function setCaseContacts(caseId, contacts) {
    return {
        type: types.SET_CASE_CONTACTS,
        contacts: contacts,
        caseId: caseId,
    };
}

export function setCaseAdvisers(caseId, advisers) {
    return {
        type: types.SET_CASE_ADVISERS,
        advisers: advisers,
        caseId: caseId,
    };
}

export function setReplyEditorHtmlAction(caseId, editorHtml) {
    return {
        type: types.SET_REPLY_EDITOR_HTML,
        payload: { caseId, editorHtml },
    };
}

export function setReplyFromAddressAction(caseId, fromAddress) {
    return {
        type: types.SET_REPLY_FROM_ADDRESS,
        payload: { caseId, fromAddress },
    }
}

export function setCasePermissionType(caseId, permissionTypeId) {
    return {
        type: types.SET_CASE_PERMISSION_TYPE,
        permissionTypeId: permissionTypeId,
        caseId: caseId,
    };
}

export function setCasePermissionUsers(caseId, users) {
    return {
        type: types.SET_PERMISSION_USERS,
        users: users,
        caseId: caseId,
    };
}

export function setReplyContactsAction(caseId, contacts) {
    return {
        type: types.SET_REPLY_CONTACTS,
        contacts: contacts,
        caseId: caseId,
    };
}

export function setReplyNonContactsAction(caseId, nonContacts) {
    return {
        type: types.SET_REPLY_NON_CONTACTS,
        nonContacts: nonContacts,
        caseId: caseId,
    };
}

export function setReplyCcNonContactsAction(caseId, emails) {
    return {
        type: types.SET_REPLY_CC_NON_CONTACTS,
        emails: emails,
        caseId: caseId,
    };
}

export function setReplyAttachmentsAction(caseId, attachments) {
    return {
        type: types.SET_REPLY_ATTACHMENTS,
        attachments: attachments,
        caseId: caseId,
    };
}

export function setReplyAttachmentAzureFileIdsAction({
    caseId,
    uploadedAttachments,
}) {
    return {
        type: types.SET_REPLY_ATTACHMENT_AZURE_FILE_IDS,
        caseId,
        uploadedAttachments,
    };
}

export function setReplyAttachmentsLinkedAction(
    caseId,
    precedentId,
    attachmentName
) {
    return {
        type: types.SET_REPLY_ATTACHMENTS_LINKED,
        name: attachmentName,
        precedentId: precedentId,
        caseId: caseId,
    };
}

export function addCaseEventAction(caseId, event) {
    return {
        type: types.ADD_CASE_EVENT,
        event: event,
        caseId: caseId,
    };
}

export function updateCaseEventChargeable({
    caseId,
    caseActivityId,
    isChargeable,
    isExcluded,
}) {
    return {
        type: types.UPDATE_CASE_EVENT_CHARGEABLE,
        caseId,
        caseActivityId,
        isChargeable,
        isExcluded,
    };
}

export function updateCaseChargeableTime({ caseId, delta }) {
    return {
        type: types.UPDATE_CASE_CHARGEABLE_TIME,
        caseId,
        delta,
    };
}

export function setCaseEmployeesAction(caseId, employees) {
    return {
        type: types.SET_CASE_EMPLOYEES,
        employees: employees,
        caseId: caseId,
    };
}

export function addReplyPrecedent(caseId, precedent) {
    return {
        type: types.ADD_REPLY_PRECEDENT,
        precedent: precedent,
        caseId: caseId,
    };
}

export function removeReplyPrecedents(caseId, redundantPrecedentIds) {
    return {
        type: types.REMOVE_REPLY_PRECEDENTS,
        payload: {
            caseId,
            redundantPrecedentIds,
        },
    };
}

export function updateCasePrecedentName(caseId, precedentId, newPrecedentName) {
    return {
        type: types.UPDATE_CASE_PRECEDENT_NAME,
        caseId,
        precedentId,
        newPrecedentName,
    };
}

export function addReplyAttachment(caseId, precedent) {
    return {
        type: types.ADD_REPLY_ATTACHMENT,
        precedent: precedent,
        caseId: caseId,
    };
}

export function removeReplyPrecedent(caseId, precedentId) {
    return {
        type: types.REMOVE_REPLY_PRECEDENT,
        precedentId: precedentId,
        caseId: caseId,
    };
}

export function removeReplyAttachment(caseId, attachmentName) {
    return {
        type: types.REMOVE_REPLY_ATTACHMENT,
        attachmentName: attachmentName,
        caseId: caseId,
    };
}

export function setPrecedentDocumentHtml(caseId, precedentId, htmlContent) {
    return {
        type: types.SET_PRECEDENT_DOCUMENT_HTML,
        precedentId: precedentId,
        htmlContent: htmlContent,
        caseId: caseId,
    };
}

export function setClaims(caseId, claims) {
    return {
        type: types.SET_CLAIMS,
        claims,
        caseId: caseId,
    };
}

export function updateETCase(caseId, etCaseNum, venueId, subVenue) {
    return {
        type: types.UPDATE_ET_CASE,
        etCaseNum,
        venueId,
        subVenue,
        caseId: caseId,
    };
}

export function setCompletedStageTasks(caseId, tasks) {
    return {
        type: types.SET_COMPLETED_STAGE_TASKS,
        tasks,
        caseId,
    };
}

export function unassignActivity(caseActivityId, prevCaseId) {
    return {
        type: types.UNASSIGN_ACTIVITY,
        caseActivityId,
        prevCaseId,
    };
}

export function setTimeEvent(e) {
    return {
        type: types.SET_TIME_EVENT,
        e,
    };
}

export function addTimeEvent(e) {
    return {
        type: types.ADD_TIME_EVENT,
        e,
    };
}

export function clearTimeEvents() {
    return {
        type: types.CLEAR_TIME_EVENTS,
    };
}

export function setCaseWindowStatus(status) {
    return {
        type: types.SET_CASE_WINDOW_STATUS,
        status,
    };
}

export function setFlexWindowStatus(status) {
    return {
        type: types.SET_FLEX_WINDOW_STATUS,
        status,
    };
}

export function setCallNoteIssueFocus(isFocus) {
    return {
        type: types.SET_CALL_NOTE_ISSUE_FOCUS,
        isFocus,
    };
}

export function setCallNoteAdviceFocus(isFocus) {
    return {
        type: types.SET_CALL_NOTE_ADVICE_FOCUS,
        isFocus,
    };
}

export function setCallNoteActionFocus(isFocus) {
    return {
        type: types.SET_CALL_NOTE_ACTION_FOCUS,
        isFocus,
    };
}

export function setCaseTimerRunning(isRunning) {
    return {
        type: types.SET_CASE_TIMER_RUNNING,
        isRunning,
    };
}

export function setCallNoteText(text, caseId) {
    return {
        type: types.SET_CALL_NOTE_TEXT,
        text,
        caseId,
    };
}

export function setCallNoteCall(call, caseId) {
    return {
        type: types.SET_CALL_NOTE_CALL,
        call,
        caseId,
    };
}

export function setCallNoteChat(chat, caseId) {
    return {
        type: types.SET_CALL_NOTE_CHAT,
        chat,
        caseId,
    };
}


export function setCallNoteCaseActivityId(caseActivityId, caseId) {
    return {
        type: types.SET_CALL_NOTE_CASE_ACTIVITY_ID,
        caseActivityId,
        caseId,
    };
}

export function updateCallNote(caseActivityId, text, duration, description) {
    return {
        type: types.UPDATE_CALL_NOTE,
        caseActivityId,
        text,
        duration,
        description,
    };
}

export function setCallNoteDescription(description) {
    return {
        type: types.SET_CALL_NOTE_DESCRIPTION,
        description,
    };
}

export function setCallNoteDescriptionFocus(isFocus) {
    return {
        type: types.SET_CALL_NOTE_DESCRIPTION_FOCUS,
        isFocus,
    };
}

export function setReplySubjectAction(subject) {
    return {
        type: types.SET_REPLY_SUBJECT,
        subject,
    };
}

export function setReplyDescription(description) {
    return {
        type: types.SET_REPLY_DESCRIPTION,
        description,
    };
}

export function setReplyPrecedentsAction(precedents) {
    return {
        type: types.SET_REPLY_PRECEDENTS,
        precedents,
    };
}

export function setCaseContract(caseId, contractId, isUnitisedTime = false) {
    return {
        type: types.SET_CASE_CONTRACT,
        caseId,
        contractId,
        isUnitisedTime,
    };
}

export function setInternalNoteText(text, caseId) {
    return {
        type: types.SET_INTERNAL_NOTE_TEXT,
        text,
        caseId,
    };
}

export function setInternalNoteFocus(hasFocus) {
    return {
        type: types.SET_INTERNAL_NOTE_FOCUS,
        hasFocus,
    };
}

export function setInternalNoteDescription(payload) {
    return {
        type: types.SET_INTERNAL_NOTE_DESCRIPTION,
        payload,
    };
}

export function setInternalNoteDescriptionFocus(hasFocus) {
    return {
        type: types.SET_INTERNAL_NOTE_DESCRIPTION_FOCUS,
        hasFocus,
    };
}

export function updateInternalNote(
    caseActivityId,
    text,
    duration,
    description
) {
    return {
        type: types.UPDATE_INTERNAL_NOTE,
        caseActivityId,
        text,
        duration,
        description,
    };
}

export function setInternalNoteCaseActivityId(caseActivityId, caseId) {
    return {
        type: types.SET_INTERNAL_NOTE_CASE_ACTIVITY_ID,
        caseActivityId,
        caseId,
    };
}

export function setConfirmCompleteTaskInfo(
    isOpen,
    channelType,
    sid,
    isEmailAllocated,
    taskSid
) {
    return {
        type: types.SET_CONFIRM_COMPLETE_TASK_INFO,
        isOpen,
        channelType,
        sid,
        isEmailAllocated,
        taskSid,
    };
}

export function setCaseDescription(caseId, description) {
    return {
        type: types.SET_CASE_DESCRIPTION,
        description,
        caseId,
    };
}

export function setCallNoteMode(mode, caseId) {
    return {
        type: types.SET_CALL_NOTE_MODE,
        mode,
        caseId,
    };
}

export function setCallNoteContact(contact, caseId) {
    return {
        type: types.SET_CALL_NOTE_CONTACT,
        contact,
        caseId,
    };
}

export function setCallNoteOutbound(outbound, caseId) {
    return {
        type: types.SET_CALL_NOTE_OUTBOUND,
        outbound,
        caseId,
    };
}

export function setEmail(email, caseId) {
    return {
        type: types.SET_EMAIL,
        email,
        caseId,
    };
}

export function incrementEventCount(caseId) {
    return {
        type: types.INCREMENT_EVENT_COUNT,
        caseId,
    };
}

export function setCaseActivityIds(caseActivityIds, caseId) {
    return {
        type: types.SET_CASE_ACTIVITY_IDS,
        caseActivityIds,
        caseId,
    };
}

export function setReplyToIndexAction(caseId, contactId, index) {
    return {
        type: types.SET_REPLY_TO_INDEX,
        caseId,
        contactId,
        index,
    };
}

export function setCallContactToIndex(caseId, index) {
    return {
        type: types.SET_CALL_CONTACT_TO_INDEX,
        caseId,
        index,
    };
}

export function addReplyCc(caseId, userId) {
    return {
        type: types.ADD_REPLY_CC,
        caseId,
        userId,
    };
}

export function setReplyIntCcsAction(caseId, userIds) {
    return {
        type: types.SET_REPLY_INT_CCS,
        caseId,
        userIds,
    };
}

export function removeReplyContactNotKnown(caseId, email) {
    return {
        type: types.REMOVE_REPLY_CONTACT_NOT_KNOWN,
        caseId,
        email,
    };
}

export function addReplyContactNotKnown(caseId, email) {
    return {
        type: types.ADD_REPLY_CONTACT_NOT_KNOWN,
        caseId,
        email,
    };
}

export function removeReplyCCContactNotKnown(caseId, email) {
    return {
        type: types.REMOVE_REPLY_CC_CONTACT_NOT_KNOWN,
        caseId,
        email,
    };
}

export function addReplyNonContactCc(caseId, email) {
    return {
        type: types.ADD_REPLY_NON_CONTACT_CC,
        caseId,
        email,
    };
}

export function removeReplyIntCc(caseId, userId) {
    return {
        type: types.REMOVE_REPLY_INT_CC,
        caseId,
        userId,
    };
}

export function setReplyCcContactsAction(caseId, ccContacts) {
    return {
        type: types.SET_REPLY_CC_CONTACTS,
        caseId,
        ccContacts,
    };
}

export function setReplyBccContactsAction(caseId, bccContacts) {
    return {
        type: types.SET_REPLY_BCC_CONTACTS,
        caseId,
        bccContacts,
    };
}

export function addReplyBcc(caseId, userId) {
    return {
        type: types.ADD_REPLY_BCC,
        caseId,
        userId,
    };
}

export function removeReplyBcc(caseId, userId) {
    return {
        type: types.REMOVE_REPLY_BCC,
        caseId,
        userId,
    };
}

export function addReplyNonContactBcc(caseId, email) {
    return {
        type: types.ADD_REPLY_NON_CONTACT_BCC,
        caseId,
        email,
    };
}

export function removeReplyNonContactBcc(caseId, email) {
    return {
        type: types.REMOVE_REPLY_NON_CONTACT_BCC,
        caseId,
        email,
    };
}

export function reassignCaseActivity(caseActivityId, oldCaseId, newCaseId) {
    return {
        type: types.REASSIGN_CASE_ACTIVITY,
        caseActivityId,
        oldCaseId,
        newCaseId,
    };
}

export function setCaseMinutes(caseId, minutes) {
    return {
        type: types.SET_CASE_MINUTES,
        caseId,
        minutes,
    };
}

export function setAdjustCaseMinutes(caseId, minutes) {
    return {
        type: types.SET_ADJUST_CASE_MINUTES,
        caseId,
        minutes,
    };
}

export function setMiscCaseActivityId(caseActivityId, caseId, group) {
    return {
        type: types.SET_MISC_CASE_ACTIVITY_ID,
        caseActivityId,
        caseId,
        group,
    };
}

export function setMiscActivityDescription(description, caseId, group) {
    return {
        type: types.SET_MISC_ACTIVITY_DESCRIPTION,
        description,
        caseId,
        group,
    };
}

export function setMiscActivityNotes(notes, caseId, group) {
    return {
        type: types.SET_MISC_ACTIVITY_NOTES,
        notes,
        caseId,
        group,
    };
}

export function setMiscActivityTypeId(miscActivityTypeId, caseId, group) {
    return {
        type: types.SET_MISC_ACTIVITY_TYPE_ID,
        miscActivityTypeId,
        caseId,
        group,
    };
}

export function setMiscActivityDate(date, caseId) {
    return {
        type: types.SET_MISC_ACTIVITY_DATE,
        date,
        caseId,
    };
}

export function setMiscActivityStartTime(date, caseId) {
    return {
        type: types.SET_MISC_ACTIVITY_START_TIME,
        date,
        caseId,
    };
}

export function setMiscActivityHours(hours, caseId) {
    return {
        type: types.SET_MISC_ACTIVITY_HOURS,
        hours,
        caseId,
    };
}

export function setMiscActivityMinutes(minutes, caseId) {
    return {
        type: types.SET_MISC_ACTIVITY_MINUTES,
        minutes,
        caseId,
    };
}

export function updateRetrospectiveMiscActivity(activity, caseId) {
    
    return {
        type: types.UPDATE_RETROSPECTIVE_MISC_ACTIVITY,
        activity,
        caseId,
    };
}

export function updateMeetingActivity(activity, caseId) {
    console.log({ activity });
    return {
        type: types.UPDATE_MEETING_ACTIVITY,
        activity,
        caseId,
    };
}

export function updateCurrentMiscActivity(activity, caseId) {
    return {
        type: types.UPDATE_CURRENT_MISC_ACTIVITY,
        activity,
        caseId,
    };
}

export function setExistingMiscActivityFiles(files, caseId, group) {
    return {
        type: types.SET_EXISTING_MISC_ACTIVITY_FILES,
        files,
        caseId,
        group,
    };
}

export function setNewMiscActivityFiles(files, caseId, group) {
    return {
        type: types.SET_NEW_MISC_ACTIVITY_FILES,
        files,
        caseId,
        group,
    };
}

export function setMiscActivityFilesToDelete(references, caseId, group) {
    return {
        type: types.SET_MISC_ACTIVITY_FILES_TO_DELETE,
        references,
        caseId,
        group,
    };
}

export function setReplyingToAction(replyingTo) {
    return {
        type: types.SET_REPLYING_TO,
        replyingTo,
    };
}

export function setEditingReplyChainAction(editingReplyChain) {
    return {
        type: types.SET_EDITING_REPLY_CHAIN,
        editingReplyChain,
    };
}

export function setEmails(emails, caseId) {
    return {
        type: types.SET_EMAILS,
        emails,
        caseId,
    };
}

export function setBackdropOpen(isOpen) {
    return {
        type: types.SET_BACKDROP_OPEN,
        isOpen,
    };
}

export function setCaseActivityDuration(duration, caseId, caseActivityId) {
    return {
        type: types.SET_CASE_ACTIVITY_DURATION,
        duration,
        caseId,
        caseActivityId,
    };
}

export function updateCaseDraftEmail(payload) {
    return {
        type: types.UPDATE_CASE_DRAFT_EMAIL,
        payload,
    };
}

export function clearCaseDraftEmail(payload) {
    return {
        type: types.CLEAR_CASE_DRAFT_EMAIL,
        payload,
    };
}

export function setEditingDraft(payload) {
    return {
        type: types.SET_EDITING_DRAFT,
        payload,
    };
}

export function setCaseHistoryMode(mode, caseId) {
    return {
        type: types.SET_CASE_HISTORY_MODE,
        mode,
        caseId,
    };
}

export function setCaseHistoryCheckAll(checked, caseId) {
    return {
        type: types.SET_CASE_HISTORY_CHECK_ALL,
        checked,
        caseId,
    };
}

export function setDownloadCaseActivityIds(ids, caseId) {
    return {
        type: types.SET_DOWNLOAD_CASE_ACTIVITY_IDS,
        ids,
        caseId,
    };
}

export function setCaseAttachmentsSelected(attachments, caseId) {
    return {
        type: types.SET_CASE_ATTACHMENTS_SELECTED,
        attachments,
        caseId,
    };
}

export function setDraftAttachmentsSelected(attachments, caseId) {
    return {
        type: types.SET_DRAFT_ATTACHMENTS_SELECTED,
        attachments,
        caseId,
    };
}

export function setShowEditor(showEditor) {
    return {
        type: types.SET_SHOW_EDITOR,
        payload: {
            showEditor,
        },
    };
}

export function setCaseResearchOpen(isOpen) {
    return {
        type: types.SET_CASE_RESEARCH_OPEN,
        isOpen,
    };
}

export function setCaseResearchSessionId(id) {
    return {
        type: types.SET_CASE_RESEARCH_SESSION_ID,
        id,
    };
}

export function setConfirmCloseOpenAction(isOpen) {
    return {
        type: types.SET_CONFIRM_CLOSE_OPEN,
        isOpen,
    };
}

export function setExistingCaseResearchSession(isExistingSession) {
    return {
        type: types.SET_EXISTING_CASE_RESEARCH_SESSION,
        isExistingSession,
    };
}

export function setCaseResearchStart(start) {
    return {
        type: types.SET_CASE_RESEARCH_START,
        start,
    };
}

export function setCaseActivityDescription(
    activityDescription,
    caseId,
    caseActivityId
) {
    return {
        type: types.SET_CASE_ACTIVITY_DESCRIPTION,
        activityDescription,
        caseId,
        caseActivityId,
    };
}

export function setCaseChargeable({ caseId, isChargeable }) {
    return {
        type: types.SET_CASE_CHARGEABLE,
        caseId,
        isChargeable,
    };
}

export function setCaseVisibleToContactsOnly({
    caseId,
    isVisibleToContactsOnly,
}) {
    return {
        type: types.SET_CASE_VISIBLE_TO_CONTACTS_ONLY,
        caseId,
        isVisibleToContactsOnly,
    };
}

export function setProcessUnallocatedTimeRequestStatus(payload) {
    return {
        type: types.SET_PROCESS_UNALLOCATED_TIME_REQUEST_STATUS,
        payload,
    };
}

export function setGetUnallocatedTimeRequestStatus(payload) {
    return {
        type: types.SET_GET_UNALLOCATED_TIME_REQUEST_STATUS,
        payload,
    };
}

export function setUnallocatedSeconds(payload) {
    return {
        type: types.SET_UNALLOCATED_SECONDS,
        payload,
    };
}

export function setUnallocatedCaseTimeEventIds(payload) {
    return {
        type: types.SET_UNALLOCATED_CASE_TIME_EVENT_IDS,
        payload,
    };
}

export function setMiscActivityTab(payload) {
    return {
        type: types.SET_MISC_ACTIVITY_TAB,
        payload,
    };
}

export function resetMiscActivity(payload) {
    return {
        type: types.RESET_MISC_ACTIVITY,
        payload,
    };
}

export function setCurrentMiscActivityOpen(payload) {
    return {
        type: types.SET_CURRENT_MISC_ACTIVITY_OPEN,
        payload,
    };
}

export function resetCurrentMiscActivity() {
    return {
        type: types.RESET_CURRENT_MISC_ACTIVITY,
    };
}

export function setCaseFilesTreeChanged(caseId, hasChanged) {
    return {
        type: types.SET_CASE_FILES_TREE_CHANGED,
        caseId,
        hasChanged,
    };
}

export function setCaseFilesForDownload(caseFiles, caseId) {
    return {
        type: types.SET_CASE_FILES_FOR_DOWNLOAD,
        caseFiles,
        caseId,
    };
}

export function setCaseOutcomeId({ caseId, caseOutcomeId }) {
    return {
        type: types.SET_CASE_OUTCOME_ID,
        payload: {
            caseId,
            caseOutcomeId,
        },
    };
}

export function setMiscActivityDraftAttachmentsSelected(caseId, attachments) {
    return {
        type: types.SET_MISC_ACTIVITY_DRAFT_ATTACHMENTS_SELECTED,
        caseId,
        attachments,
    };
}

export function setMiscActivityAttachmentsAction(caseId, attachments) {
    return {
        type: types.SET_MISC_ACTIVITY_ATTACHMENTS,
        caseId,
        attachments,
    };
}

export function setCaseHistoryDateFrom(caseId, dateFrom) {
    return {
        type: types.SET_CASE_HISTORY_DATE_FROM,
        caseId,
        dateFrom,
    };
}

export function setCaseHistoryDateTo(caseId, dateTo) {
    return {
        type: types.SET_CASE_HISTORY_DATE_TO,
        caseId,
        dateTo,
    };
}

export function resetCaseHistoryFilters(caseId) {
    return {
        type: types.RESET_CASE_HISTORY_FILTERS,
        caseId,
    };
}

export function setCaseHistoryActivity(caseId, activityName, activity) {
    return {
        type: types.SET_CASE_HISTORY_ACTIVITY,
        caseId,
        activityName,
        activity,
    };
}
export function removeCaseHistoryActivity(caseId, activityName) {
    return {
        type: types.REMOVE_CASE_HISTORY_ACTIVITY,
        caseId,
        activityName,
    };
}

export function setCaseHistoryChargeable(caseId, isChargeable, label) {
    return {
        type: types.SET_CASE_HISTORY_CHARGEABLE,
        caseId,
        isChargeable,
        label,
    };
}
export function removeCaseHistoryChargeable(caseId, label) {
    return {
        type: types.REMOVE_CASE_HISTORY_CHARGEABLE,
        caseId,
        label,
    };
}

export function setCaseHistoryDirection(caseId, direction, label) {
    return {
        type: types.SET_CASE_HISTORY_DIRECTION,
        caseId,
        direction,
        label,
    };
}

export function removeCaseHistoryDirection(caseId, direction) {
    return {
        type: types.REMOVE_CASE_HISTORY_DIRECTION,
        caseId,
        direction,
    };
}

export function setCaseHistoryUser(caseId, userId, name) {
    return {
        type: types.SET_CASE_HISTORY_USER,
        caseId,
        userId,
        name,
    };
}

export function removeCaseHistoryUser(caseId, userId) {
    return {
        type: types.REMOVE_CASE_HISTORY_USER,
        caseId,
        userId,
    };
}

export function setMiscActivityUploadStatus(payload) {
    return {
        type: types.SET_MISC_ACTIVITY_UPLOAD_STATUS,
        payload,
    }
}

export function setReplyUploadStatus(payload) {
    return {
        type: types.SET_REPLY_UPLOAD_STATUS,
        payload,
    }
}

export function clearNonPrecedentAttachments(payload) {
    return {
        type: types.CLEAR_NON_PRECEDENT_ATTACHMENTS,
        payload,
    }
}

export function setMeetingCardMeeting(caseId, meeting) {
    return {
        type: types.SET_MEETING,
        meeting,
        caseId,
    };
}

export function setMeetingField(caseId, field, value) {
    return {
        type: types.SET_MEETING_FIELD,
        caseId,
        field,
        value
    };
}

export function resetMeeting(caseId) {
    return {
        type: types.RESET_MEETING,
        caseId,
    };
}
