import React from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Box,
    CircularProgress,
} from "@material-ui/core";
import useConversation from "../../hooks/queries/useConversation";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
    perfScroll: {
        maxHeight: "calc(100vh - 500px)",
        marginTop: '10px'
    },
    message: {
        padding: '12px',
        borderRadius: '5px',
        margin: '5px',
        width: '70%',
        '&.in': {
            background: '#f4f4f6',
        },
        '&.out': {
            background: '#030b5d',
            color: 'white',
            marginLeft: 'auto',
        }
    }
}));

const ConversationDialog = ({
    caseActivityId,
    onClose
}) => {
    const classes = useStyles();

    const { data: conversation, isLoading } = useConversation(caseActivityId);

    console.log({ conversation });

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    return (
        <Dialog
            onClose={onClose}
            open={!!caseActivityId}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent>
                {!conversation && <h6>Conversation does not exist!</h6>}
                {conversation &&
                    <>
                        <Box display="flex" justifyContent="space-between">
                            <h4>{conversation.source}</h4>
                            <h4>{conversation.from}</h4>
                            <h4>{conversation.state}</h4>
                        </Box>
                        <PerfectScrollbar className={classes.perfScroll}>
                            {conversation.messages.map(m =>
                                <Box className={`${classes.message} ${m.direction === 1 ? 'out' : 'in'}`} key={m.index} display="flex" flexDirection="column">
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>{m.author}</Box>
                                        <Box>{format(new Date(m.dateCreated), 'dd/MM/yy kk:mm')}</Box>
                                    </Box>

                                    <Box>{m.message}</Box>
                                </Box>
                            )}

                        </PerfectScrollbar>
                    </>}

            </DialogContent>
        </Dialog>
    );
};

export default ConversationDialog;
