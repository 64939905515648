import React, { useState } from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import useCaseOutcomes from "../../hooks/queries/useCaseOutcomes";
import { viewModeStates } from "../../constants/viewModeStates";
import { Edit as EditIcon, Replay as UndoIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import caseService from "../../services/caseService";
import { useDispatch, useSelector } from "react-redux";
import { setSnackAction } from "../../redux/actions/snackActions";
import {
    addCaseEventAction,
    setCaseOutcomeId,
} from "../../redux/actions/caseActions";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        maxWidth: "240px",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "unset",
        },
    },
    fullGrid: {
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
        gridColumn: "1 / span 4",
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(3),
    },
    rightHalf: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
    },
    leftHalf: {
        minWidth: "max-content",
    },
}));

function ETOutcomeCard({ isEtSaving, caseId, adviceTypeId }) {
    const classes = useStyles();
    const [viewMode, setViewMode] = useState(viewModeStates.VIEWING);
    const [saving, setSaving] = useState(false);
    const [forDropdown, setForDropdown] = useState(false);

    const outcomes = useCaseOutcomes({ isEt: true, forDropdown, adviceTypeId });
    const dispatch = useDispatch();
    const { cases } = useSelector((state) => state.caseReducer);
    const caseOutcomeId = cases[caseId]?.caseSummary?.caseOutcomeId;
    const caseOutcome = outcomes?.data?.find(
        (x) => x.id === caseOutcomeId
    )?.description;
    const { userProfile, users } = useSelector((state) => state.userReducer);
    const adviserId = userProfile.userId;

    const isEditing = viewMode === viewModeStates.EDITING;

    const onEditClick = (e) => {
        e.stopPropagation();
        setViewMode(viewModeStates.EDITING);
    };

    const onUndoClick = (e) => {
        e.stopPropagation();
        setViewMode(viewModeStates.VIEWING);
    };

    const handleSave = async (e) => {
        try {
            setSaving(true);
            await caseService.updateCaseOutcome({
                caseId,
                adviserId,
                caseOutcomeId: e.target.value,
            });
            dispatch(
                setCaseOutcomeId({ caseId, caseOutcomeId: e.target.value })
            );
            dispatch(
                addCaseEventAction(caseId, {
                    adviserId,
                    itemType: "Outcome",
                    eventTime: new Date(),
                    text:
                        outcomes?.data?.find((x) => x.id === e.target.value)
                            ?.description || "N/A",
                    direction: 2,
                    person: users[adviserId].name,
                })
            );
            dispatch(setSnackAction("ET case details updated", "success"));
            setViewMode(viewModeStates.VIEWING);
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    e?.message || "There was an error updating ET case details",
                    "error"
                )
            );
        } finally {
            setSaving(false);
        }
    };

    const EditModeSelect = () => {
        setForDropdown(true);
        if (outcomes.isLoading) return <CircularProgress size={20} />;

        if (outcomes.isError)
            return (
                <Alert variant="outlined" severity="error">
                    {outcomes.error?.message ||
                        "Unable to load ET outcomes list."}
                </Alert>
            );

        return (
            <FormControl className={classes.formControl} variant="outlined">
                <InputLabel>Outcome</InputLabel>
                <Select
                    value={caseOutcomeId ?? ""}
                    onChange={handleSave}
                    label="Outcome"
                    labelWidth="Outcome"
                >
                    {outcomes.data.map((oc) => (
                        <MenuItem key={oc.id} value={oc.id}>
                            {oc.description}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const rightComponent =
        isEtSaving || saving ? (
            <CircularProgress size={20} />
        ) : isEditing ? (
            <IconButton onClick={onUndoClick}>
                <UndoIcon />
            </IconButton>
        ) : (
            <IconButton onClick={onEditClick}>
                <EditIcon />
            </IconButton>
        );

    return (
        <Card>
            <CardContent>
                <div className={classes.fullGrid}>
                    <div className={classes.leftHalf}>
                        <Typography variant="h6">Outcome</Typography>
                    </div>
                    <div className={classes.rightHalf}>{rightComponent}</div>
                </div>
                {isEditing ? (
                    <EditModeSelect />
                ) : (
                    <div>
                        <Typography variant="subtitle2">Outcome</Typography>
                        <Typography>{caseOutcome ?? "-"}</Typography>
                    </div>
                )}
            </CardContent>
        </Card>
    );
}

export default ETOutcomeCard;
