import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseOutcomeService from "../../services/caseOutcomeService";

const useCaseOutcomes = ({ isEt, adviceTypeId, forDropdown }) =>
    useQuery(
        [queryKeys.caseOutcomes, isEt, forDropdown, adviceTypeId],
        () => caseOutcomeService.getCaseOutcomes(isEt, forDropdown, adviceTypeId),
        {
            staleTime: Infinity,
        }
    );

export default useCaseOutcomes;
