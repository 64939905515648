import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputAdornment,
    TextField,
    IconButton,
    Grid,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Add as AddIcon } from "@material-ui/icons";
import { setSnackAction } from "../redux/actions/snackActions";
import caseEmployeeService from "../services/caseEmployeeService";
import { setCaseEmployeesAction } from "../redux/actions/caseActions";
import { format } from "date-fns";
import ManagerSelect from "../components/ManagerSelect";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing(3)
    }
}));

const emptyEmployee = {
    employeeId: null,
    employeeNumber: null,
    name: "",
    position: "",
    employmentStartDate: null,
    protectedCharacteristics: [],
    manager: {},
    isStartEstimate: false,
    isLeaver: false,
    dateOfBirth: null,
    notes: "",
};

const EmployeeFormDialog = ({
    open,
    existingEmployee,
    handleFormClose,
    setExcludedEmployeeIds,
    excludedEmployeeIds
}) => {
    const classes = useStyles();

    const [employee, setEmployee] = useState(JSON.parse(JSON.stringify(emptyEmployee)));
    const [newProtectedCharacteristic, setNewProtectedCharacteristic] = useState("");
    const [saving, setSaving] = useState(false);

    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const employees = cases[caseId].employees;
    const accountId = cases[caseId].account.accountId;
    const isSaasCase = cases[caseId].isSaasCase;

    const dispatch = useDispatch();

    const handleChangeProtectedCharacteristic = (e, index) => {
        setEmployee({
            ...employee,
            protectedCharacteristics: employee.protectedCharacteristics.map((c, i) => i === index ? e.target.value : c).filter(c => c)
        });
    };

    const addProtectedCharacteristic = () => {
        if (newProtectedCharacteristic) {
            setEmployee({
                ...employee,
                protectedCharacteristics: [...employee.protectedCharacteristics, newProtectedCharacteristic]
            });
            setNewProtectedCharacteristic("");
        }
    };

    const handleChangeEmployee = e => {
        setEmployee({
            ...employee,
            [e.target.name]: e.target.value
        });
    };

    const handleCancel = () => {
        setEmployee(JSON.parse(JSON.stringify(emptyEmployee)));
        setNewProtectedCharacteristic("");
        handleFormClose();
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let protectedCharacteristics = employee.protectedCharacteristics;
        if (newProtectedCharacteristic) {
            protectedCharacteristics.push(newProtectedCharacteristic);
        }

        let employeeToSend = {
            employeeId: employee.employeeId,
            employeeNumber: employee.employeeNumber,
            name: employee.name  ,
            position: employee.position,
            employmentStartDate: employee.employmentStartDate,
            protectedCharacteristics: protectedCharacteristics.join(","),
            isStartEstimate: employee.isStartEstimate,
            isLeaver: employee.isLeaver,
            dateOfBirth: employee.dateOfBirth,
            notes: employee.notes,
            manager: employee.manager,
        };
        let newEmployeeId = null;
        if (!existingEmployee) {
            try {
                setSaving(true);
                const newEmployee = await caseEmployeeService.addNewEmployee({ caseId, accountId, employee: employeeToSend, isSaasCase });
                newEmployeeId = newEmployee[0].employeeId;
                dispatch(setCaseEmployeesAction(caseId, [...employees, ...newEmployee]));
                dispatch(setSnackAction("Saved!", "success"));
                handleCancel();
            }
            catch (error) {
                dispatch(setSnackAction(error?.message || "Could not add employee to case", "error"))
            }
            finally {
                setSaving(false);
                setExcludedEmployeeIds([...excludedEmployeeIds, newEmployeeId]);
            }
        }

        else {
            try {
                setSaving(true);
                await caseEmployeeService.editCaseEmployees({ employees: [employeeToSend], isSaasCase });
                dispatch(setCaseEmployeesAction(caseId, employees.map(e => e.employeeId === employeeToSend.employeeId ?
                    {
                        ...e,
                        employeeNumber: employeeToSend.employeeNumber,
                        name: employeeToSend.name,
                        position: employeeToSend.position,
                        employmentStartDate: employeeToSend.employmentStartDate,
                        protectedCharacteristics: employeeToSend.protectedCharacteristics,
                        isLeaver: employeeToSend.isLeaver,
                        isStartEstimate: employeeToSend.isStartEstimate,
                        dateOfBirth: employeeToSend.dateOfBirth,
                        notes: employeeToSend.notes,
                        manager: employeeToSend.manager,
                    } : e)));
                dispatch(setSnackAction("Saved!", "success"));
                handleCancel();
            }
            catch (error) {
                dispatch(setSnackAction(error?.message || "Could not edit case employee", "error"))
            }
            finally {
                setSaving(false);
            }
        }
    };


    useEffect(() => {
        existingEmployee ? setEmployee({
            ...existingEmployee,
            protectedCharacteristics: existingEmployee.protectedCharacteristics ? existingEmployee.protectedCharacteristics.split(",") : []
        }) : setEmployee(JSON.parse(JSON.stringify(emptyEmployee)));
    }, [existingEmployee]);

    return (
        <Dialog open={open} onClose={handleFormClose} maxWidth="sm" fullWidth>
            <DialogTitle>{existingEmployee ? "Edit" : "Add"} Employee</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <DialogContentText>{employee.youManageEmployee ? "Employee details from HR information system:" : "Please enter employee details"}</DialogContentText>
                    <FormGroup>
                        {employee.youManageEmployee ? <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.firstName || " "}
                                    label="First name"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.workEmail || " "}
                                    label="Work email"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.address || " "}
                                    label="Address"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.employmentStartDate ? format(new Date(employee.employmentStartDate), "dd/MM/yyyy") : " "}
                                    label="Start date"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.position || " "}
                                    label="Position"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.manager || " "}
                                    label="Manager"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.lastName || " "}
                                    label="Last name"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.personalEmail || " "}
                                    label="Personal email"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.personalTelephone || " "}
                                    label="Personal telephone"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.dateOfBirth ? format(new Date(employee.dateOfBirth), "dd/MM/yyyy") : " "}
                                    label="DOB"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.leavingDate ? format(new Date(employee.youManageEmployee.leavingDate), "dd/MM/yyyy") : " "}
                                    label="Leaving date"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    value={employee.youManageEmployee.department || " "}
                                    label="Department"
                                    disabled
                                />
                            </Grid>
                        </Grid> :
                            <>
                                <TextField
                                    name="name"
                                    required
                                    value={employee.name}
                                    onChange={handleChangeEmployee}
                                    inputProps={{ maxLength: 80 }}
                                    label="Employee Name"
                                />
                                <TextField
                                    className={classes.textField}
                                    name="employeeNumber"
                                    value={employee.employeeNumber || " "}
                                    onChange={handleChangeEmployee}
                                    inputProps={{ maxLength: 80 }}
                                    label="Employee Number"
                                />
                                <TextField
                                    className={classes.textField}
                                    name="position"
                                    value={employee.position}
                                    onChange={handleChangeEmployee}
                                    label="Employee Position"
                                />
                                <ManagerSelect
                                    size="small"
                                    employee={employee}
                                    accountId={accountId}
                                    setEmployee={setEmployee}
                                />
                                <KeyboardDatePicker
                                    className={classes.textField}
                                    name="employmentStartDate"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Employment Start Date"
                                    value={employee?.employmentStartDate ?? null}
                                    onChange={date => setEmployee({ ...employee, employmentStartDate: date })}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={employee.isStartEstimate}
                                            onChange={e => setEmployee({ ...employee, isStartEstimate: e.target.checked })}
                                        />
                                    }
                                    label="Start date is an estimate"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={employee.isLeaver}
                                            onChange={e => setEmployee({ ...employee, isLeaver: e.target.checked })}
                                        />
                                    }
                                    label="Employee is a leaver"
                                />
                                <KeyboardDatePicker
                                    name="dateOfBirth"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Employee Date of Birth"
                                    value={employee?.dateOfBirth ?? null}
                                    onChange={date => setEmployee({ ...employee, dateOfBirth: date })}
                                />
                            </>}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={employee.youManageEmployee ? 6 : 12}>
                                <DialogContentText className={classes.textField}>Protected characteristics</DialogContentText>
                                {employee.protectedCharacteristics.map((c, index) =>
                                    <TextField
                                        fullWidth
                                        key={index}
                                        value={c}
                                        onChange={e => handleChangeProtectedCharacteristic(e, index)}
                                    />
                                )}
                                <FormControl className={classes.textField} fullWidth>
                                    <Input
                                        type="text"
                                        value={newProtectedCharacteristic}
                                        inputProps={{ maxLength: 255 }}
                                        onChange={e => setNewProtectedCharacteristic(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={addProtectedCharacteristic}><AddIcon /></IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    name="notes"
                                    value={employee.notes}
                                    inputProps={{ maxLength: 255 }}
                                    multiline
                                    onChange={handleChangeEmployee}
                                    label="General employee notes"
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    {saving ? <CircularProgress /> :
                        <>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit" disabled={!employee.youManageEmployee && !employee.name?.length}>{existingEmployee ? "Done" : "Submit"}</Button>
                        </>}
                </DialogActions>
            </form>
        </Dialog>
    )
};

export default EmployeeFormDialog;